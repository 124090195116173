import React, { useState } from "react";
import { t } from "../../services/translation/TranslationUtils";
import Button from "./Button";
import Archive from "../../utils/icons/Archive";
import Edit from "../../utils/icons/Edit";
import Unarchive from "../../utils/icons/Unarchive";
import { NO_RESULTS_FOUND } from "../../constants/Constants";
import View from "../../utils/icons/View";
import CloneIcon from "../../utils/icons/CloneIcon";
import CommonServices from "../../services/CommonService";
import List from "./List";
import Popup from "../molecules/Popup";
import { FaSort } from "react-icons/fa";
import Reports from "../../utils/icons/Reports";

interface Props {
  data?: any;
  headers?: any;
  values?: any;
  isAction?: boolean;
  actionText?: string;
  actionClass?: string;
  editClass?: string;
  archiveClass?: string;
  barcodeClass?: string;
  viewClass?: string;
  handleEdit?: (value: any) => void;
  handleArchive?: (value: any) => void;
  handleView?: (value: any) => void;
  handleClone?: (value: any) => void;
  handleSort?: () => void;
  handleAnswerView?: (value:any)=>void;
}

const TableStructure: React.FC<Props> = (props) => {
  const [state, setState] = useState({
    viewpopup: false,
    presentItem: [],
    popupTitle: "",
  });

  const formattedValue = (column: any, value: any, index?: any) => {
    if (column !== "activity") {
      if (column.endsWith("_at")) {
        return CommonServices.formatDateIntoLocalDateTime(
          value.table[column],
          column !== "expired_at"
        );
      } else if (Array?.isArray(value.table[column])) {
        return value.table[column]?.length > 0 ? (
          <span
            className="cursor-pointer manage-users-action"
            title={t("View")}
            onClick={() =>
              setState((prev: any) => ({
                ...prev,
                viewpopup: true,
                presentItem: value.table[column],
                popupTitle: props?.headers?.[index]?.name ?? column,
              }))
            }
          >
            <View />
          </span>
        ) : (
          "-"
        );
      } else {
        return value.table[column];
      }
    }
    return "";
  };

  const showEditIcon = (value: any): boolean => {
    if (
      value.table["expired_at"] &&
      new Date(value.table["expired_at"].replace(" ", "T")) <= new Date()
    ) {
      return false;
    }
    return true;
  };

  return (
    <div className='TableHeight manage_border mt-2'>
      <table className="table views-table views-view-table cols-7">
        <thead className='position-sticky'>
          <tr className="table_header ">
            {props.headers?.map((value: any) => (
              <th
                key={value.name}
                className="border-bottom-0"
                style={{ width: `${value.width}%` }}
              >
                {t(value.name)}{" "}
                {value.isSort && (
                  <span>
                    {props.handleSort && <FaSort onClick={props.handleSort} />}
                  </span>
                )}
              </th>
            ))}
            {props.isAction && (
              <th
                className={`border-bottom-0 ${props.actionClass ?? "table_actions"
                  }`}
              >
                {props.actionText ?? t("Actions")}
              </th>
            )}
          </tr>
        </thead>
        <tbody>
          {props.data?.length ? Object.values(props.data).map((value: any, index) => (
            <tr key={index}>
              {props.values?.map((column: any, columnIndex: number) => {
                const headerLabel = props.headers[columnIndex]?.name || "";
                return (
                  <td
                    key={column}
                    className="text-break"
                    data-label={headerLabel}
                  >
                    <span className={column === 'activity' ? (value.table[column] ? 'activestatus' : 'inactivestatus') : ""}>
                      {formattedValue(column, value, columnIndex)}
                    </span>
                  </td>
                )
              })}

              {props.isAction && (
                <td className="text-break gap-3 ipad-icons">
                  <div className="width-max_content">
                    {/* Buttons for medium screens (centered, stacked, full width) */}
                    <div className="d-none d-md-flex d-lg-none flex-column align-items-center w-100">
                      {/* Edit Button */}
                      {props.handleEdit && showEditIcon(value) && (
                        <Button
                          hoverText={t('Edit')}
                          className="btn btn-sm resposive-btn btn-primary mb-2 w-100"
                          handleClick={() => props.handleEdit?.(value.table)}
                        >
                          Edit
                        </Button>
                      )}
                      {/* View Button */}
                      {props.handleView && ('files' in value?.table ? value?.table['files']?.[0]?.file_path : true) && (
                        <Button
                          hoverText={t('View')}
                          className="btn btn-sm resposive-btn btn-secondary mb-2 w-100"
                          handleClick={() => props.handleView?.(value.table)}
                        >
                          View
                        </Button>
                      )}
                      {/* Clone Button */}
                      {props.handleClone && (
                        <Button
                          hoverText={t('Clone')}
                          className="btn btn-sm resposive-btn btn-success mb-2 w-100"
                          handleClick={() => props.handleClone?.(value.table)}
                        >
                          Clone
                        </Button>
                      )}
                      {/* Archive Button */}
                      {props.handleArchive && (
                        <Button
                          hoverText={value.status ? t('Deactivate') : t('Activate')}
                          className="btn btn-sm resposive-btn btn-warning w-100"
                          handleClick={() => props.handleArchive?.(value.table)}
                        >
                          {value.status ? 'Deactivate' : 'Activate'}
                        </Button>
                      )}
                    </div>

                    {/* Buttons for larger screens (inline) */}
                    <div className="d-none d-lg-flex">
                      {props.handleEdit && showEditIcon(value) && (
                        <Button
                          hoverText={t('Edit')}
                          className={`${props.editClass ??
                            'px-0 border-0 bg-transparent shadow-none manage-users-action me-2'}`
                          }
                          handleClick={() => props.handleEdit?.(value.table)}
                        >
                          <Edit />
                        </Button>
                      )}
                      {props.handleView && ('files' in value?.table ? value?.table['files']?.[0]?.file_path : true) && (
                        <Button
                          hoverText={t('View')}
                          className={`${props.viewClass ??
                            'px-0 border-0 bg-transparent shadow-none manage-users-action me-2'}`
                          }
                          handleClick={() => props.handleView?.(value.table)}
                        >
                          <View />
                        </Button>
                      )}
                      {props.handleClone && (
                        <Button
                          hoverText={t('Clone')}
                          className={`${props.archiveClass ??
                            'px-0 border-0 bg-transparent shadow-none manage-users-action me-2'}`
                          }
                          handleClick={() => props.handleClone?.(value.table)}
                        >
                          <CloneIcon />
                        </Button>
                      )}
                      {props.handleArchive && (
                        <Button
                          hoverText={value.status ? t('Deactivate') : t('Activate')}
                          className={`${props.archiveClass ??
                            'px-0 border-0 bg-transparent shadow-none manage-users-action me-2'}`
                          }
                          handleClick={() => props.handleArchive?.(value.table)}
                        >
                          {value.status ? <Archive /> : <Unarchive />}
                        </Button>
                      )}
                      {
                        props.handleAnswerView && (
                          <Button
                          hoverText={t('View Answer')}
                          className={`${props.viewClass ??
                            'px-0 border-0 bg-transparent shadow-none manage-users-action me-2'}`
                          }
                          handleClick={() => props.handleAnswerView?.(value.table)}
                        >
                          <Reports />
                        </Button>
                      )}
                    </div>
                  </div>
                </td>
              )}



            </tr>
          )) :
            <td colSpan={props.isAction ? (props.headers.length + 1) : props.headers.length} className="text-center text-danger">
              {t(NO_RESULTS_FOUND)}
            </td>
          }
        </tbody>
      </table>
      {state?.viewpopup && (
        <Popup
          title={t(state.popupTitle)}
          bodyclassName=""
          body={<List items={state?.presentItem ?? []} />}
          close={() =>
            setState((prev: any) => ({
              ...prev,
              viewpopup: false,
              presentItem: [],
              popupTitle: "",
            }))
          }
        />
      )}
    </div>
  );
};

export default TableStructure;
