import React, { useEffect, useState } from "react";
import { filterValues } from "../State";
import { ManageUserFilterProps } from "../Types";
import { renderInput } from "../../../../services/form/FormElements";
import Button from "../../../atoms/Button";
import { t } from "../../../../services/translation/TranslationUtils";
import styles from "../Users.module.css";
import ResetBtn from "../../../common/ResetBtn";

const ManageUserFilters: React.FC<ManageUserFilterProps> = ({
  handleChange,
  options,
  data,
  handleSubmit,
  handleClear
}) => {
  const [rows, setRows] = useState<any[]>([]);

  useEffect(() => {
    let row = filterValues(options);
    setRows(row);
  }, [options]);

  return (
    <div className={`${styles.manage_employee_filter}`}>
      {rows.map((value: any) => (
        <React.Fragment key={value?.name}>
          {renderInput(
            () => { },
          value,
            handleChange,
            data,
          )}
        </React.Fragment>
      ))}
      <div className="text-end">
        <div className="d-flex align-items-center justify-content-end">
          <Button
            className={`search_btn me-3`}
            title={t('Search')}
            handleClick={handleSubmit}
          />
          <ResetBtn handleResetClick={handleClear}/>
        </div>
      </div>
    </div>
  );
};

export default ManageUserFilters;
