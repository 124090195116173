import { TabComponentProps } from "../../../pages/forms/context/Interfaces";
import { renderInput } from "../../../services/form/FormElements";

const AdministratorsTab: React.FC<TabComponentProps> = ({
  state,
  dispatch,
  handleChange,
  formConfig,
  removeError,
}) => {
  return (
    <div>
      <div className="row">
        {formConfig.map((fields: any, index: number) => (
          <div key={""} className="mb-3">
            {Object.values(fields)?.map((field: any) => (
              <div key={field?.name} className="">
                {renderInput(
                  removeError,
                  field,
                  (e: any, fieldName: string) => handleChange(e, fieldName),
                  state[state?.currentTab]?.data,
                  state[state?.currentTab]?.errors
                )}
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};
export default AdministratorsTab;
