import React from "react";

const Reports: React.FC = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 316 401" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M27.4206 0.125H214.584L215.76 0.712947L314.927 99.88L315.123 100.468V372.296L314.927 375.431L314.143 379.351L312.967 382.683L311.399 385.818L309.635 388.562L308.459 390.13L306.499 392.286L304.932 393.658L302.58 395.421L299.444 397.185L296.504 398.557L293.173 399.537L291.409 399.929L289.645 400.125H26.0487L23.109 399.733L20.1692 398.949L17.0335 397.773L13.5058 395.813L10.3701 393.462L8.99822 392.286L6.25447 389.15L3.90268 385.622L2.13884 381.899L0.962947 377.979L0.375 374.255V25.6027L1.15893 21.4871L2.13884 18.5473L3.51072 15.6076L4.68661 13.4518L6.64644 10.708L8.80224 8.35626L10.1741 6.98438L13.3098 4.63259L16.8375 2.67277L20.3652 1.30089L23.5009 0.516965L27.4206 0.125ZM57.7978 28.9344L54.4661 29.1304L51.1344 29.7183L47.2148 30.8942L44.0791 32.4621L40.9433 34.4219L38.1996 36.7737L36.6317 38.3416L34.8679 40.4974L33.1041 43.2411L31.5362 46.3768L30.5563 48.9246L29.7724 52.0603L29.3804 55.392L29.1844 59.7036V341.526L29.3804 345.446L30.1643 350.15L31.3402 353.677L32.9081 356.813L34.084 358.773L35.8478 361.125L38.1996 363.672L40.7474 365.828L43.8831 367.788L47.0188 369.356L50.5465 370.532L55.2501 371.316L72.3005 371.512H234.574L259.856 371.316L263.775 370.728L267.303 369.748L270.831 368.18L273.77 366.416L276.318 364.456L278.474 362.496L280.238 360.341L281.806 358.185L283.178 355.833L284.745 351.913L285.529 348.974L285.921 346.818L286.117 344.662V128.885L212.036 128.689L208.116 128.101L204.981 127.122L201.649 125.75L198.121 123.594L195.573 121.438L194.79 121.046L194.398 120.262H194.006L193.614 119.478L192.046 117.714L189.89 114.383L188.126 110.659L187.146 107.523L186.558 104.78L186.166 100.664L185.97 29.1304L184.598 28.9344H57.7978Z" fill="black" />
      <path d="M71.5166 314.089H242.805L246.921 314.481L249.469 315.461L252.212 317.028L254.564 319.184L256.328 321.732L257.308 323.888L257.896 326.24L258.092 327.808V329.571L257.504 332.707L256.524 335.255L254.76 337.999L253.976 338.979L252.016 340.546L250.057 341.722L247.313 342.702L245.353 343.094H69.7528L66.813 342.31L64.0693 340.938L62.3054 339.566L60.5416 337.803L58.9737 335.255L57.9938 332.903L57.4059 330.159V327.416L58.1898 323.888L59.7577 320.752L61.1295 318.988L62.6974 317.42L64.6572 316.049L67.205 314.873L69.5568 314.285L71.5166 314.089Z" fill="black" />
      <path d="M71.1246 257.058H242.609L246.137 257.254L248.293 257.842L251.428 259.41L253.192 260.782L254.172 261.761L255.544 263.329L257.112 266.269L257.896 269.209L258.092 270.581V272.54L257.504 275.676L256.524 278.224L254.956 280.576L253.192 282.536L250.644 284.299L247.705 285.475L245.157 286.063H70.3407L67.597 285.475L65.2452 284.495L63.0894 283.124L61.1295 281.36L59.9536 279.792L58.5818 277.44L57.6019 274.304L57.4059 272.932V270.385L57.9938 267.445L58.9737 264.897L60.3456 262.741L60.9336 261.957H61.3255L61.7175 261.174L63.2853 259.802L66.4211 258.038L69.1648 257.254L71.1246 257.058Z" fill="black" />
      <path d="M70.5367 200.027H244.765L247.509 200.419L249.861 201.399L252.212 202.771L254.368 204.731L255.936 206.886L257.308 209.63L257.896 211.982L258.092 213.55V215.51L257.504 218.449L256.524 220.997L254.76 223.741L253.78 224.721L251.82 226.485L248.685 228.052L245.941 228.836H69.5568L67.205 228.248L64.8532 227.269L62.6974 225.701L60.5416 223.545L58.9737 220.997L57.9938 218.645L57.4059 215.902V213.158L58.3858 209.042L59.7577 206.494L61.3255 204.535L62.8934 202.967L65.4411 201.399L67.9889 200.419L70.5367 200.027Z" fill="black" />
      <path d="M71.5166 142.8H144.226L146.97 143.192L149.322 143.976L151.477 145.152L153.633 146.916L155.397 148.876L156.573 150.835L157.553 153.383L157.945 156.127L157.749 160.439L156.769 163.378L155.397 165.73L153.829 167.69L151.673 169.454L148.93 170.826L146.186 171.61L145.206 171.806H69.9487L66.813 171.022L64.0693 169.65L61.7175 167.69V167.298H61.3255L59.5617 164.946L58.3858 162.594L57.6019 160.047L57.4059 158.871V156.127L58.1898 152.599L59.1697 150.443L60.5416 148.288L62.1094 146.524L64.8532 144.564L67.009 143.584L69.5568 142.996L71.5166 142.8Z" fill="black" />
    </svg>
  );
}

export default Reports;