import React, { ChangeEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { APICALL } from "../../../services/ApiServices";
import { Forms } from "../../../routes/ApiEndpoints";
import TitleAtom from "../../common/TitleAtom";
import Button from "../../atoms/Button";
import { t } from "../../../services/translation/TranslationUtils";
import ManageUserTabs from "../users/manage/ManageUserTabs";
import TableStructure from "../../atoms/TableStructure";
import {
  manageState,
  tableHeaders,
  tableValues,
} from "../../../pages/forms/context/State";
import Pagination from "../../molecules/Paginations";
import Popup from "../../molecules/Popup";
import ManageFormFilters from "./ManageFormFilters";

const ManageFormOrganism: React.FC = () => {
  const [state, setState] = useState(manageState);
  const navigate = useNavigate();
  const user = JSON.parse(JSON.parse(localStorage.getItem('persist:root') ?? '{}')?.auth ?? '{}');
  useEffect(() => {
    fetchData(manageState.postData);
  }, []);

  const fetchData = async (postData: any) => {
    const response = await APICALL.service(
      Forms +
        `?status=${postData.status}&page=${postData.current_page}&q=${postData.search}&order=${postData.order}&order_type=${postData.order_type}&form_type=${postData.form_type}`,
      "GET"
    );
    if (response.status === 200) {
      setState((prevState: any) => ({
        ...prevState,
        options:response?.data?.types,
        postData: {
          ...prevState.postData,
          total_pages: response.data.total_pages,
        },
        data: response.data.data,
      }));
    }
  };

  const handleView = (value: any) => {
    navigate(`/forms/view?id=${value?.id}&token=${user?.token}`);
  };

  const handleEdit = (value: any) => {
    navigate(`/forms/create?edit=${value?.id}`);
  };

  const handleAnswerView = (value: any) => {
    navigate(`/forms/form-data?id=${value?.id}`);
  };

  const handlePaginationClick = (pageNumber: number) => {
    let postData = {
      ...state.postData,
      current_page: pageNumber,
    };
    setState((prevState: any) => ({
      ...prevState,
      postData: postData,
    }));
    fetchData(postData);
  };

  const handleClear = () => {
    setState((prevState: any) => ({
      ...prevState,
      filters:manageState.filters,
      postData: manageState.postData,
      }));
    fetchData(manageState.postData);
  };

  const handleArchivePopup = (value: any) => {
    setState((prevState: any) => ({
      ...prevState,
      popup: value,
    }));
  };

  const handleArchive = async (value: any) => {
    let postData = {
      status: !value.activity,
      status_change: true,
    };
    const response = await APICALL.service(
      Forms + "/" + value.id,
      "DELETE",
      postData
    );

    if (response.status === 200) {
      setState((prevState: any) => ({
        ...prevState,
        popup: false,
      }));
      fetchData(state.postData);
    }
  };

  const handleTabsClick = (tab: string) => {
    let status = null;
    if (tab !== "all") {
      status = tab === "active";
    }
    let postData = {
      ...state.postData,
      status: status,
    };
    setState((prevState: any) => ({
      ...prevState,
      postData: postData,
    }));
    fetchData(postData);
  };
  const handleSort = () => {
    let value = state.postData.order === "DESC" ? "ASC" : "DESC";
    let postData = {
      ...state.postData,
      order_type: "created_at",
      order: value,
    };
    setState((prevState: any) => ({
      ...prevState,
      postData: postData,
    }));
    fetchData(postData);
  };

  const handleChange = (
            e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
            field: string
          ) => {
            setState((prevState: any) => ({
              ...prevState,
              postData:{
                ...prevState.postData,
                [field]: e?.target?.value ?? e,
              },
              filters: {
                ...prevState.filters,
                [field]: e?.target?.value ?? e,
              },
            }));
          };

  return (
    <>
      <div className="search-bar py-3">
        <div className="row pb-3">
          <TitleAtom title={t("Manage forms")} />
          <div className="col-6">
            <div className="d-flex justify-content-end gap-3">
            <Button
                className={`create_button px-3`}
                handleClick={() => navigate("/forms/form-data")}
                title={ t("View form answers")}
              />
              <Button
                className={`create_button px-3`}
                handleClick={() => navigate("/forms/create")}
                title={"+ " + t("Create forms")}
              />
            </div>
          </div>
        </div>
        <ManageFormFilters
          handleChange={handleChange}
          options={state?.options}
          data={state?.filters}
          handleSubmit={() => fetchData(state.postData)}
          handleClear={handleClear}
        />

        <ManageUserTabs
          handleClick={handleTabsClick}
          status={state?.postData?.status}
        />
      </div>

      <TableStructure
        isAction
        data={state.data}
        headers={tableHeaders}
        values={tableValues}
        handleEdit={handleEdit}
        handleArchive={handleArchivePopup}
        handleView={handleView}
        handleSort={handleSort}
        handleAnswerView={handleAnswerView}
      />
      {state?.postData?.total_pages > 1 && (
        <Pagination
          currentPage={state?.postData?.current_page}
          totalPages={state?.postData?.total_pages}
          handlePaginationClick={handlePaginationClick}
        />
      )}
      {state?.popup && (
        <Popup
          body={t("Are you sure, you want to change the activity") + "?"}
          yestext={t("Yes")}
          notext={t("No")}
          submit={() => handleArchive(state.popup)}
          cancel={() =>
            setState((prevState: any) => ({
              ...prevState,
              popup: false,
            }))
          }
        />
      )}
    </>
  );
};

export default ManageFormOrganism;
