import React, { ChangeEvent, useEffect, useState } from "react";
import { APICALL } from "../../../services/ApiServices";
import { initialstate } from "./State";
import CreatePostForm from "./CreatPostForm";
import {
  CREATE_POSTS,
  MANAGE_NEWS,
  fileData,
} from "../../../routes/ApiEndpoints";
import Popup from "../../molecules/Popup";
import AddOptionsMolecule from "../../molecules/AddOptionsMolecule";
import { useNavigate } from "react-router-dom";
import ActionButtonGroup from "../../molecules/ActionButtonGroup";
import CustomNotify from "../../atoms/CustomNotify";
import FormValidation from "../../../services/formvalidation/Validation";
import { Files } from "../../common/CommonInterfaces";
import { t } from "../../../services/translation/TranslationUtils";

interface CreatePostOrganismProps {
  newsid: number;
  postid?: number | null;
  handleSave: () => void;
  options: any;
}

const CreatePostOrganism: React.FC<CreatePostOrganismProps> = ({
  newsid,
  options,
  handleSave,
  postid = null,
}) => {
  const [state, setState] = useState(initialstate);
  const navigate = useNavigate();
  useEffect(() => {
    if (newsid != null) {
      setState((prev: any) => ({
        ...prev,
        form: {
          ...prev.form,
          news_id: Number(newsid) ?? newsid,
        },
        user_options: options,
      }));
      fetchData();
    }
  }, [newsid != null]);

  const fetchData = async () => {
    try {
      const postdata = {
        posts: true,
      };
      const response = await APICALL.service(
        MANAGE_NEWS + "/" + newsid,
        "POST",
        postdata
      );

      if ([200, 201].includes(response?.status)) {
        setState((prevState: any) => ({
          ...prevState,
          form: {
            ...prevState.form,
            news_id: Number(newsid) ?? newsid,
          },
          user_options: response?.data?.options ?? [],
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const removeError = (fieldName: any, key?: string) => {
    if (key) {
      const updatedForm: any = { ...state.form };
      // Find the index of the item to remove
      const index = updatedForm?.[key]?.file_path.indexOf(fieldName);

      if (index !== -1) {
        // Create a copy of the state and remove the item
        updatedForm[key].file.splice(index, 1);
        updatedForm[key].file_path.splice(index, 1);
        updatedForm[key].file_name.splice(index, 1);
        updatedForm[key].fileId.splice(index, 1);

        // Update the state with the modified form data
        setState((prevState: any) => ({
          ...prevState,
          form: updatedForm,
        }));
      }
    } else {
      setState((prevState: any) => ({
        ...prevState,
        errors: { ...prevState?.errors, [fieldName]: "" },
      }));
    }
  };

  const handleFileOperation = (file: File, field: any) => {
    if (file) {
      const newFile: Files = {
        file,
        file_path: file && URL.createObjectURL(file),
        file_name: file?.name,
        fileId: null,
      };

      setState((prevState: any) => ({
        ...prevState,
        form: {
          ...prevState.form,
          [field.name]: {
            file: [...prevState.form[field.name].file, newFile.file],
            file_path: [
              ...prevState.form[field.name].file_path,
              newFile.file_path,
            ],
            file_name: [
              ...prevState.form[field.name].file_name,
              newFile.file_name,
            ],
            fileId: [...prevState.form[field.name].fileId, newFile.fileId],
          },
        },
      }));
    }
  };

  const handleChange = (e: any, field: any) => {
    if (field?.type === "file") {
      handleFileOperation(e, field);
    } else {
      let value = e?.target?.value ?? e;
      if (field?.type === "svg") {
        setState((prevState: any) => ({
          ...prevState,
          survey_popup: !state?.survey_popup,
        }));
      } else {
        setState((prevState: any) => ({
          ...prevState,
          form: {
            ...prevState.form,
            [field?.name]: value,
          },
          survey_popup: false,
        }));
      }
    }
  };

  const handleSubmit = async () => {
    let error = FormValidation?.textareaValidation(state?.form?.body?.trim());
    if (!error) {
      try {
        const accept = ["image", "video", "pdf"];
        const object: any = {};
        const fileDetails: any = {};
        const loadingIcon = document.getElementById("loading-div-id");
        if (loadingIcon) loadingIcon.style.display = "block";
        for (const [key, value] of Object.entries(state?.form)) {
          if (
            key &&
            accept?.includes(key) &&
            value !== null &&
            typeof value === "object" &&
            "file" in value &&
            value?.file?.length
          ) {
            const uploadPromises = value.file.map(async (fileObj: any) => {
              const formData = new FormData();
              formData.append("file", fileObj);
              const path = `posts/${key}`;
              formData.append("path", path);
              const response = await APICALL.service(
                fileData,
                "POST",
                formData
              );
              if (response?.status === 200) {
                const { fileId } = response?.data;
                console.log(response?.data);
                fileDetails[key] = fileDetails[key] || [];
                fileDetails[key].push({
                  fileId,
                });
                object[key] = object[key] || [];
                object[key].push(fileId);
              } else {
                throw new Error("File upload failed");
              }
            });
            await Promise.all(uploadPromises);
          }
        }
        if (loadingIcon) loadingIcon.style.display = "none";
        // Append other data
        const data = {
          news_id: state?.form?.news_id,
          body: state?.form?.body,
          entities: {
            image: {
              fileId:
                object?.image && object?.image?.length
                  ? object?.image
                  : state?.form?.image?.fileId,
            },
            video: {
              fileId:
                object?.video && object?.video?.length
                  ? object?.video
                  : state?.form?.video?.fileId,
            },
            pdf: {
              fileId:
                object?.pdf && object?.pdf?.length
                  ? object?.pdf
                  : state?.form?.pdf?.fileId,
            },
            survey: state?.form?.survey,
          },
        };
        const response = await APICALL.service(
          CREATE_POSTS + (postid ? "/" + postid : ""),
          "POST",
          data
        );
        CustomNotify({
          type: response.status === 200 ? "success" : "error",
          message: response.message,
        });
        if (response.status === 200) {
          handleSave();
        }
      } catch (error) {
        CustomNotify({ type: "error", message: "Error while creating news" });
      }
    } else {
      setState((prevState: any) => ({
        ...prevState,
        errors: { ...prevState?.errors, body: error },
      }));
    }
  };

  return (
    state && (
      <>
        <CreatePostForm
          data={state?.form}
          errors={state?.errors}
          handleChange={handleChange}
          removeError={removeError}
        />
        <ActionButtonGroup
          maindivcss="navigation-buttons"
          backTitle={t("Back")}
          saveAndNextTitle={t("Post")}
          handleBackClick={() => navigate("/")}
          handleSaveAndNextClick={handleSubmit}
        />
        {state?.survey_popup && (
          <Popup
            title={t("Create survey")}
            body={
              <AddOptionsMolecule
                handleSubmit={(data: any) =>
                  handleChange(data, { name: "survey" })
                }
                survey={state?.form?.survey}
                state={state}
              />
            }
            bodyclassName="survey_popup_body"
            cancel={() =>
              setState((prev) => ({ ...prev, survey_popup: false }))
            }
          />
        )}
      </>
    )
  );
};

export default CreatePostOrganism;
