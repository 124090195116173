import React from "react";
import CreateFormOrganism from "../../components/organisms/forms/CreateFormOrganism";
import { FormProvider } from "./context/FormContext";

const CreateForm: React.FC = () => {
  return (
    <FormProvider>
      <CreateFormOrganism />
    </FormProvider>
  );
};

export default CreateForm;
