import ManageFaqOrganism from "../../components/templates/Faq/ManageFaqOrganism";

export const ManageFaq = () => {
  return (
    <>
      <ManageFaqOrganism />
    </>
  );
};
export default ManageFaq;
