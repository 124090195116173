import React, { useState, ChangeEvent, FocusEvent, MouseEvent } from "react";
import LabelField from "../atoms/LabelField";
import InputTextfield from "../atoms/InputTextField";

interface LabelWithInputFieldProps {
  type?: string;
  className?: string;
  placeholder?: string;
  handleChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  handleClick?: (event: FocusEvent<HTMLInputElement>) => void | undefined;
  label?: string;
  value?: any;
  name?: string;
  id?: string;
  isDisabled?: boolean;
  customStyle?: React.CSSProperties;
  mandatory?: boolean;
  labelClassName?: string;
  error?: string;
  handleBlur?: () => void;
  readOnly?: boolean;
  mainclass?: string;
}

export const InputWithLabel: React.FC<LabelWithInputFieldProps> = ({
  type,
  className = "form-control",
  placeholder,
  handleChange,
  handleClick,
  label,
  value,
  name,
  id = "",
  isDisabled,
  customStyle,
  mandatory,
  labelClassName,
  error,
  handleBlur,
  readOnly,
  mainclass
}) => {

  return (
    <div className={mainclass ?? `col-lg-12 mb-3"`}>
      <LabelField
        title={label}
        className={labelClassName}
        mandatory={mandatory}
      />
      <InputTextfield
        id={id}
        type={type}
        className={`${className} form-control`}
        value={value}
        isDisabled={isDisabled}
        placeholder={placeholder}
        customStyle={customStyle}
        handleChange={handleChange}
        handleClick={handleClick} // Use onFocus instead of handleFocus
        name={name}
        error={error}
        handleBlur={() => handleBlur && handleBlur()}
        readOnly={readOnly}
      />
    </div>
  );
};
