import { useEffect, useState } from "react";
import { ManageUserFilterProps } from "../users/Types";
import { filterManageValues } from "./State";
import { renderInput } from "../../../services/form/FormElements";
import Button from "../../atoms/Button";
import { t } from "../../../services/translation/TranslationUtils";
import ResetBtn from "../../common/ResetBtn";


const ManageFormDataFilters: React.FC<ManageUserFilterProps> = ({
  handleChange,
  options,
  data,
  handleSubmit,
  handleClear
}) => {
  const [rows, setRows] = useState<any[]>([]);

  useEffect(() => {
    let row = filterManageValues(options);
    setRows(row);
  }, [options]);

  return (
    <>
      <div className="d-flex my-3 gap-4">
        {rows && rows.map((value: any) => (
          <div className={`flex-fill w-25`} key={value?.name}>
            {
              renderInput(() => { },
                value,
                handleChange,
                data,
              )
            }
          </div>
        ))}
        <div className="d-flex">
          <Button
            className="search_btn me-3"
            title={t('Search')}
            handleClick={handleSubmit}
          />
          <ResetBtn handleResetClick={handleClear} />
        </div>
      </div>
    </>
  );
};

export default ManageFormDataFilters;
