import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import TitleAtom from "../../common/TitleAtom";
import { t } from "../../../services/translation/TranslationUtils";
import Button from "../../atoms/Button";
import { APICALL } from "../../../services/ApiServices";
import {
  FAQ,
  FAQ_CATEGORY,
  UPDATE_ALL_FAQ,
} from "../../../routes/ApiEndpoints";
import FaqItem from "../../molecules/FaqItem";
import Popup from "../../molecules/Popup";
import { getFormFields, INITIAL_FAQ_DETAILS, State } from "../Faq/Interface";
import PopupForm, { FormField } from "../../molecules/PopupForm";
import CustomNotify from "../../atoms/CustomNotify";
import FormValidation from "../../../services/formvalidation/Validation";

const FaqTemplate: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const [state, setState] = useState<State>({
    isPopupOpen: false,
    faqCategory: null,
    faqDetails: INITIAL_FAQ_DETAILS,
    isDeletePopupOpen: false,
    faqToDelete: null,
    error: {
      question: "",
      answer: "",
    },
  });

  // Fetch FAQ category data
  const fetchData = async () => {
    if (!id) return;
    try {
      const response = await APICALL?.service(`${FAQ_CATEGORY}/${id}`, "GET");
      if (response?.status === 200) {
        setState((prevState) => ({
          ...prevState,
          faqCategory: {
            id: response?.data?.id,
            title: response?.data?.title,
            faqs: response?.data?.faqs,
          },
        }));
      } else {
        console.error("Failed to fetch FAQ data:", response?.message);
      }
    } catch (error) {
      console.error("Error fetching FAQ data:", error);
    }
  };

  // Handle popup open and close
  const togglePopup = (isOpen: boolean, faqDetails = INITIAL_FAQ_DETAILS) => {
    setState((prevState) => ({
      ...prevState,
      isPopupOpen: isOpen,
      faqDetails,
      error: { question: "", answer: "" },
    }));
  };

  const handleDeletePopup = (faqId: number | null) => {
    if (!faqId) return;
    const faqToDelete = state.faqCategory?.faqs?.find(
      (faq) => faq?.id === faqId
    );
    setState((prevState) => ({
      ...prevState,
      isDeletePopupOpen: true,
      faqToDelete: faqToDelete || null,
    }));
  };

  const handleDelete = async () => {
    const faqId = state.faqToDelete?.id;
    if (!faqId) return;

    try {
      const response = await APICALL?.service(`${FAQ}/${faqId}`, "DELETE");
      if (response?.status === 200) {
        fetchData();
        CustomNotify({
          type: "success",
          message: t(response?.message),
        });
        setState((prevState) => ({
          ...prevState,
          isDeletePopupOpen: false,
          faqToDelete: null,
        }));
      }
    } catch (error) {
      console.error("Error deleting FAQ:", error);
    }
  };

  const UpdateFaq = async () => {
    try {
      const response = await APICALL?.service(
        UPDATE_ALL_FAQ,
        "POST",
        state?.faqCategory
      );
      if (response?.status === 200) {
        CustomNotify({
          type: "success",
          message: t(response?.message),
        });
      } else {
        CustomNotify({
          type: "error",
          message: t(response?.message),
        });
      }
    } catch (error) {
      console.error("Error updating FAQ:", error);
    }
  };

  // Handle input changes
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e?.target;
    setState((prevState) => ({
      ...prevState,
      faqDetails: {
        ...prevState?.faqDetails,
        [name]: value,
      },
    }));

    setState((prevState) => ({
      ...prevState,
      error: {
        ...prevState.error,
        [name]: "",
      },
    }));
  };

  // Handle editor changes
  const handleEditorChange = (data: string) => {
    setState((prevState) => ({
      ...prevState,
      faqDetails: {
        ...prevState?.faqDetails,
        answer: data,
      },
      error: {
        ...prevState.error,
        answer: "",
      },
    }));
  };

  // Handle API (delete, update, add)
  const handleApi = async (url: string, method: string, data?: object) => {
    try {
      const response = await APICALL?.service(url, method, data);
      if (response?.status === 200) {
        fetchData();
        CustomNotify({
          type: "success",
          message: t(response?.message),
        });
      }
      if (response?.status === 500) {
        fetchData();
        CustomNotify({
          type: "error",
          message: t(response?.message),
        });
      }
    } catch (error) {
      console.error(`Error performing ${method} action:`, error);
    }
  };

  // Edit FAQ
  const handleEdit = (faqId: number | null) => {
    const faqToEdit = state.faqCategory?.faqs?.find((faq) => faq?.id === faqId);
    if (faqToEdit) togglePopup(true, faqToEdit);
  };

  const handleSubmit = async () => {
    const errors: Record<string, string> = {};
    const formData: any = state.faqDetails;
    const tabFields = getFormFields(state);
    tabFields.forEach((fieldGroup: any) => {
      Object.values(fieldGroup).forEach((field: any) => {
        if (field.required) {
          if (field.type === "text") {
            errors[field.name] = FormValidation?.nameValidation(
              formData[field?.name] ?? ""
            );
          } else {
            errors[field.name] = FormValidation?.textareaValidation(
              formData[field?.name] ?? ""
            );
          }
        }
      });
    });
    const hasErrors = Object.values(errors).some((error) => error?.length > 0);
    if (hasErrors) {
      setState((prevState) => ({
        ...prevState,
        error: {
          ...prevState.error,
          ...errors,
        },
      }));
      return;
    }

    // Continue with form submission if no errors
    const { question, answer, id: faqId, status } = state.faqDetails;
    const url = faqId ? `${FAQ}/${faqId}` : FAQ;
    const method = faqId ? "PUT" : "POST";

    const data = {
      category_title: state.faqCategory?.title,
      question,
      answer,
      status,
    };

    await handleApi(url, method, data);
    togglePopup(false);
  };

  const handleToggle = (faqId: number | null, currentStatus: boolean) => {
    const updatedFaqs = state?.faqCategory?.faqs?.map((faq) => {
      if (faq.id === faqId) {
        return { ...faq, status: !currentStatus };
      }
      return faq;
    });

    setState((prevState: any) => ({
      ...prevState,
      faqCategory: {
        ...prevState?.faqCategory,
        faqs: updatedFaqs,
      },
    }));
  };

  useEffect(() => {
    fetchData();
  }, [id]);

  // Define the fields for the popup form
  const formFields: FormField[] = [
    {
      label: "Question",
      name: "question",
      value: state?.faqDetails?.question ?? "",
      type: "text",
      handleChange: handleChange,
      error: state?.error?.question,
    },
    {
      label: "Answer",
      name: "answer",
      value: state?.faqDetails?.answer ?? "",
      type: "editor",
      handleChange: handleEditorChange,
      error: state?.error?.answer,
    },
  ];

  return (
    <div className="container-fluid px-0">
      <div className="search-bar py-4">
        <div className="row align-items-center">
          <div className="col-md-6">
            <TitleAtom title={state?.faqCategory?.title} />
          </div>
          <div className="col-md-6">
            <Button
              className="create_button px-3 float-end"
              title={"+ " + t("Add Question")}
              handleClick={() => togglePopup(true)}
            />
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <div className="faq-list">
            {state.faqCategory?.faqs?.map((faq, index) => (
              <FaqItem
                key={faq?.id}
                questionNumber={index + 1}
                question={faq?.question}
                answer={faq?.answer}
                status={faq?.status}
                handleToggle={() => handleToggle(faq?.id, faq?.status)}
                onDelete={() => handleDeletePopup(faq?.id)}
                onEdit={() => handleEdit(faq?.id)}
              />
            ))}
          </div>
        </div>
      </div>

      <div className="d-flex justify-content-between">
        <Button
          title="Back"
          className="back_btn"
          handleClick={() => {
            navigate("/faq-categories");
          }}
        />
        <Button
          title="Save"
          className="create_button"
          handleClick={() => UpdateFaq()}
        />
      </div>

      {state.isPopupOpen && (
        <Popup
          title={state?.faqDetails?.id ? t("Edit FAQ") : t("Add FAQ")}
          cancel={() => togglePopup(false)}
          body={<PopupForm fields={formFields} />}
          submit={handleSubmit}
          yestext={t("Save")}
          notext={t("Back")}
        />
      )}

      {state.isDeletePopupOpen && (
        <Popup
          title={t("Confirm Deletion")}
          cancel={() =>
            setState((prevState) => ({
              ...prevState,
              isDeletePopupOpen: false,
              faqToDelete: null,
            }))
          }
          body={
            <div>
              <p>{t("Are you sure you want to delete this FAQ?")}</p>
            </div>
          }
          submit={handleDelete}
          yestext={t("Yes")}
          notext={t("No")}
        />
      )}
    </div>
  );
};

export default FaqTemplate;
