import React from 'react';
import PreviewField from "./PreviewField";
import { FormField } from '../../../pages/forms/context/Interfaces';

const FormPreview: React.FC<{
  subSectionId: number | null;
  fields: FormField[];
  isSelectField: (id: number) => boolean;
  onEditField: (field: FormField) => void;
  onDeleteField: (fieldId: string) => void;
  onMoveField: (draggedId: string, targetId: string, position: 'before' | 'after' | 'inside') => void;
  onAddSubField: (parent_id: string) => void;
}> = ({
  subSectionId,
  fields,
  isSelectField,
  onEditField,
  onDeleteField,
  onMoveField,
  onAddSubField
}) => {
  return (
    <div className="p-4 border h-100">
      <h2 className="Title-forms  mb-4">Form Preview</h2>
      <div className="space-y-2">
        {fields?.map(field => (
          <PreviewField
            isSelectField={isSelectField}
            subSectionId={subSectionId}
            key={field.key}
            field={field}
            onEditField={onEditField}
            onDeleteField={onDeleteField}
            onMoveField={onMoveField}
            onAddSubField={onAddSubField}
          />
        ))}
      </div>
    </div>
  );
};

export default FormPreview;