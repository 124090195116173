import React, { createContext, useContext, useReducer, ReactNode } from "react";
import { initialState } from "./State";
import { FormState } from "./Interfaces";
import { FormAction } from "./Action";
import FormReducer from "./FormReducer";

const FormStateContext = createContext<FormState | undefined>(undefined);
const FormDispatchContext = createContext<
  React.Dispatch<FormAction> | undefined
>(undefined);

export const useFormState = () => {
  const context = useContext(FormStateContext);
  if (!context) {
    throw new Error("useFormState must be used within a GroupProvider");
  }
  return context;
};

export const useFormDispatch = () => {
  const context = useContext(FormDispatchContext);
  if (!context) {
    throw new Error("useGroupDispatch must be used within a GroupProvider");
  }
  return context;
};

interface FormProviderProps {
  children: ReactNode;
}

export const FormProvider: React.FC<FormProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(FormReducer, initialState);

  return (
    <FormStateContext.Provider value={state}>
      <FormDispatchContext.Provider value={dispatch}>
        {children}
      </FormDispatchContext.Provider>
    </FormStateContext.Provider>
  );
};
