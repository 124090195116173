import { title } from "process";
import { FormField } from "../../molecules/PopupForm";

export interface FaqDetails {
  id: number | null;
  question: string;
  answer: string;
  status: boolean;
}

export interface FaqCategory {
  id: number | null;
  title: string;
  faqs: FaqDetails[];
}

export interface FaqField {
  id: number;
  name: string;
}

export interface State {
  isPopupOpen: boolean;
  faqCategory: FaqCategory | null;
  faqDetails: FaqDetails;
  isDeletePopupOpen: boolean;
  faqToDelete: FaqDetails | null;
  error: {
    question: string;
    answer: string;
  };
}

export const INITIAL_FAQ_DETAILS: FaqDetails = {
  id: null,
  question: "",
  answer: "",
  status: true,
};

export interface ManageFaqInterface {
  isPopupOpen: boolean;
  faqFields: FaqField[];
  formData: { id: number | null; name: string };
  error: { name: string };
}

export const INITIAL_MANAGE_FAQ: ManageFaqInterface = {
  isPopupOpen: false,
  faqFields: [],
  formData: { id: null, name: "" },
  error: { name: "" },
};

export interface ManagerFaqFilterProps {
  value: string;
  handleFilter: (search: string) => void;
  handleSearch: () => void;
  handleClear: () => void;
}

export const tableHeaders = [
  { name: "Title", width: 22 },
  { name: "created_at", width: 43, isSort: true },
  { name: "Activity", width: 8 },
];

export const tableValues = ["title", "created_at", "activity"];

export interface ManageState {
  data: FaqCategory[]; // Array of FAQ categories
  postData: {
    limit: number;
    current_page: number;
    search: string;
    total_pages: number;
    status: null;
    order_type: string;
    order: string;
  };
  popup: boolean; // Whether the popup is open or not
}
export const manageState: ManageState = {
  data: [],
  postData: {
    limit: 10,
    current_page: 1,
    search: "",
    total_pages: 1,
    status: null,
    order_type: "created_at",
    order: "DESC",
  },
  popup: false,
};

export const getFormFields = (state: State) => [
  {
    0: {
      name: "question",
      title: "Question",
      type: "text",
      required: true,
      class: {},
    },
  },
  {
    0: {
      name: "answer",
      title: "Answer",
      type: "editor",
      required: true,
      class: {},
    },
  },
];

export const getManageFields = (INITIAL_MANAGE_FAQ: ManageFaqInterface) => [
  {
    0: {
      name: "name",
      title: "Title",
      type: "text",
      required: true,
      class: {},
    },
  },
];
