import React, { ChangeEvent } from 'react';

interface Props {
    name?: string;
    value?: string;
    checked?: boolean;
    handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
    handleClick?: () => void;
    label: React.ReactNode;
    customStyle?: React.CSSProperties;
    id: string;
    className?: string;
    disable?: boolean;
}

const RadioField: React.FC<Props> = ({
    name = '',
    value = '',
    checked = 0,
    handleChange,
    handleClick,
    label,
    customStyle = {},
    id = '',
    className,
    disable = false,
}) => {

    return (
        <label key={id} style={{ cursor: 'pointer' }} className={className || ''}>
            <input
                style={{ ...customStyle, ...{ marginRight: '10px' } }}
                type="radio"
                name={name}
                checked={!!checked}
                onChange={handleChange}
                onClick={() => handleClick && handleClick()}
                id={id}
                value={value}
                disabled={disable}
                className="form-check-input shadow-none cursor-pointer select_border min-width1em"
            />
            {label}
        </label>
    );
};

export default RadioField;
