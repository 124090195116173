import React, { useRef, useState } from "react";
import Button from "../../atoms/Button";
import { PreviewFieldProps } from "../../../pages/forms/context/Interfaces";
import { useFormState } from "../../../pages/forms/context/FormContext";
import Dragicon from "../../../../src/static/images/Dragicon"
import Edit from "../../../utils/icons/Edit";
import { Delete } from '../../../utils/icons/Delete';

const PreviewField: React.FC<PreviewFieldProps> = ({
  subSectionId,
  isSelectField,
  field,
  level = 0,
  onEditField,
  onDeleteField,
  onMoveField,
  onAddSubField
}) => {
  const fieldRef = useRef<HTMLDivElement>(null);
  const state = useFormState();
  const [dropTarget, setDropTarget] = useState<'before' | 'after' | 'inside' | null>(null);

  const handleDragStart = (e: React.DragEvent<HTMLDivElement>) => {
    e.dataTransfer.setData('fieldId', field.key);
    e.dataTransfer.effectAllowed = 'move';
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();

    const rect = fieldRef.current?.getBoundingClientRect();
    if (!rect) return;

    const mouseY = e.clientY;
    const threshold = rect.height / 3;

    // Determine drop position based on mouse location
    if (field.form_element === subSectionId) {
      if (mouseY < rect.top + threshold) {
        setDropTarget('before');
      } else if (mouseY > rect.bottom - threshold) {
        setDropTarget('after');
      } else {
        setDropTarget('inside');
      }
    } else {
      if (mouseY < rect.top + rect.height / 2) {
        setDropTarget('before');
      } else {
        setDropTarget('after');
      }
    }
  };

  const handleDragLeave = () => {
    setDropTarget(null);
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();

    const draggedId = e.dataTransfer.getData('fieldId');
    if (draggedId && draggedId !== field.key && dropTarget) {
      onMoveField(draggedId, field.key, dropTarget);
    }
    setDropTarget(null);
  };

  const getDropIndicatorStyles = () => {
    if (!dropTarget) return {};

    if (dropTarget === 'inside' && field.form_element === subSectionId) {
      return { backgroundColor: 'rgba(59, 130, 246, 0.1)' };
    }

    const borderStyle = '2px solid #3B82F6';
    return {
      borderTop: dropTarget === 'before' ? borderStyle : undefined,
      borderBottom: dropTarget === 'after' ? borderStyle : undefined
    };
  };

  const getLabel = (id: number) => {
    let formelements = state.options.form_element_options;
    return formelements.find(element => element.value === id)?.label
  }

  return (
    <div
      ref={fieldRef}
      draggable
      onDragStart={handleDragStart}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
      className={`mb-4 transition-all ${level > 0 ? 'ml-6' : ''}`}
      style={getDropIndicatorStyles()}
    >
      <div className="p-4 border rounded hover:bg-gray-50">
        <div className="d-flex align-items-center justify-content-between">
          <div className="d-flex items-center me-3">
            <span className="cursor-move me-2">
              <Dragicon />
            </span>
            <div>
              <span className="font-medium text-break">{field.title || '<Unnamed Field>'}</span>
              <span className="text-sm text-gray-500 ml-2">({getLabel(field.form_element)})</span>
              {field.required && <span className="text-red-500 ml-2 text-danger">*</span>}
            </div>
          </div>
          <div className="d-flex justify-content-between gap-1">
            <span onClick={() => onEditField(field)}>
              <Edit />
            </span>

            <span className="">
              {field.form_element === subSectionId && (
                <Button
                  className="create_button"
                  handleClick={() => onAddSubField(field.key)}
                >
                  Add Field
                </Button>
              )}
            </span>
            <span className="" onClick={() => onDeleteField(field.key)}>
              <Delete />
            </span>

          </div>
        </div>

        {isSelectField(field.form_element) && field.options && field.options.length > 0 && (
          <div className="mt-2 text-sm text-gray-600">
            Options: {field.options.map((option) => option.label).join(', ')}
          </div>
        )}

        {field.form_element === subSectionId && field.fields && (
          <div className="mt-4 border-l-2 border-gray-200">
            {field.fields.map(subField => (
              <PreviewField
                isSelectField={isSelectField}
                subSectionId={subSectionId}
                key={subField.key}
                field={subField}
                level={level + 1}
                onEditField={onEditField}
                onDeleteField={onDeleteField}
                onMoveField={onMoveField}
                onAddSubField={onAddSubField}
              />
            ))}
          </div>
        )}
      </div>
    </div >
  );
};

export default PreviewField;
