import { renderInput } from "../../../services/form/FormElements";
import ViewPreviewFile from "../../molecules/ViewPreviewFile";
interface FormViewProps {
  title:string;
  values: any;
  data: any;
}

const FormViewLayout: React.FC<FormViewProps> = ({
    title,
    values,
    data,
  }) => {
    return (
      <div className="d-flex flex-column align-items-center my-4 px-4 py-4 w-100">
        <div><h1>{title}</h1></div>
        {values?.length>0 &&<div className="px-4 py-4 form overflow-y-scroll d-flex flex-column gap-3 my-3 w-100">
                    {Object.values(values).map((value: any) => (
                      <div className={`flex-1 w-100`} key={value?.title}>
                        {
                          renderInput(
                            ()=>{},
                            value,
                            ()=>{},
                            data,
                            ()=>{}
                          )
                        }
                        <div className="d-flex">
                        {
                          Object.entries(data).map(([key1,values])=>{
                            if(value?.name===key1){
                              if (values !== null && typeof values === 'object' && 'file_path' in values && values?.file_path && values?.file_path != '') {
                                return (
                                  <div className="flex-1" key={key1}>
                                      <ViewPreviewFile
                                          key={key1}
                                          fileData={values}
                                      />
                                  </div>
                              );
                            }
                            }
                            return null
                          })
                        }
                    </div>
                      </div>
                      
                    ))}
                  </div>}
      </div>
    );
};
export default FormViewLayout;
