import { FormMode } from "../../../components/common/CommonEnums";
import { FormState, FormStateTabs } from "./Interfaces";

// Function to handle UPDATE_FIELD action
export const handleUpdateField = (
  tabState: any,
  state: FormState,
  action: any
): FormState => {
  if (Array.isArray(action.value)) {
    return {
      ...state,
      [state.currentTab]: {
        ...tabState,
        data: {
          ...tabState.data,
          [action.field]: Array.from(new Set(action.value)),
        },
        // errors: {
        //   ...tabState.errors,
        //   [action.field]: validateField(action.field, action.value),
        // },
      },
    };
  }

  if (action?.field === "options") {
    return {
      ...state,
      options: {
        ...state.options,
        ...action.value,
      },
    };
  }

  return {
    ...state,
    [state.currentTab]: {
      ...tabState,
      data: {
        ...tabState.data,
        [action.field]: action.value,
      },
      // errors: {
      //   ...tabState.errors,
      //   [action.field]: validateField(action.field, action.value),
      // },
    },
  };
};

// Function to handle SET_ERROR action
export const handleSetError = (state: FormState, action: any): FormState => {
  if (action?.field) {
    return {
      ...state,
      [state.currentTab]: {
        ...state[state.currentTab],
        errors: {
          ...state[state.currentTab].errors,
          [action?.field]: "",
        },
      },
      tabs: state.tabs.map((tab) =>
        tab.id === state.currentTab ? { ...tab, error: false } : tab
      ),
    };
  } else {
    const tab: keyof FormStateTabs = action.tab;
    return {
      ...state,
      [action.tab]: {
        ...state[tab],
        errors: {
          ...state[tab].errors,
          ...action.errors,
        },
      },
      tabs: state.tabs.map((tab) =>
        tab.id === action?.tab ? { ...tab, error: action?.hasError } : tab
      ),
    };
  }
};

export const handleInitialStateUpdate = (
  state: FormState,
  action: any
): FormState => {
  const { options, data } = action?.value || {};
  const {
    title,
    form_type,
    isActive,
    private_form,
    record_on_user_profile,
    disable_result_export,
    anonymous,
    show_in_library,
    public: isPublic,
    single_completion,
    has_expiration_date,
    show_results_to_user,
    manager,
    user,
    team,
    admin,
    fields,
  } = data || {};

  return {
    ...state,
    options,
    initialCall: false,
    mode: FormMode.UPDATE,
    general_details: {
      ...state.general_details,
      data: {
        ...state.general_details.data,
        title,
        form_type,
        isActive,
        private_form,
        record_on_user_profile,
        disable_result_export,
        anonymous,
        show_in_library,
        public: isPublic,
        single_completion,
        has_expiration_date,
        show_results_to_user,
      },
    },
    layout: {
      ...state.layout,
      data: {
        ...state.layout.data,
        fields: fields,
      },
    },
    administrators: {
      ...state.administrators,
      data: {
        ...state.administrators.data,
        manager,
        user,
        team,
        admin,
      },
    },
  };
};

// Utility to validate fields
export const validateField = (field: string, value: any): string => {
  let error = "";
  const fields: Record<string, { required: boolean; type: string }> = {
    title: { required: true, type: "string" },
    form_type: { required: true, type: "number" },
  };

  if (fields[field]?.required && (!value || value.length === 0)) {
    error = `${field} is required.`;
  } else if (fields[field]?.type === "string" && typeof value !== "string") {
    error = `${field} must be a string.`;
  }
  return error;
};
