import React from "react";
import { TabComponentProps } from "../../../pages/forms/context/Interfaces";
import { renderInput } from "../../../services/form/FormElements";

const GeneralDetailsTab: React.FC<TabComponentProps> = ({
  state,
  dispatch,
  handleChange,
  formConfig,
  removeError,
}) => {
  return (
    <div className="row align-items-end">
      {formConfig.map((fields: any, index: number) => (
        <div key={""} className="col-4 mb-3">
          {Object.values(fields)?.map((field: any) => (
            <div key={field?.name} className="w-100">
              {renderInput(
                removeError,
                field,
                (e: any, fieldName: string) => handleChange(e, fieldName),
                state[state?.currentTab]?.data,
                state[state?.currentTab]?.errors
              )}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};
export default GeneralDetailsTab;
