import FormValidation from "./Validation";

export const inputValidation = (value: any, type: any, length = 255) => {
    switch (type) {
        case 'required_text':
            return FormValidation.nameValidation(value, length);

        case 'not_required_text':
            return FormValidation.textValidation(value, length);

        case 'range':
        case 'number':
            return FormValidation.numberValidation(value);

        case 'textarea':
            return FormValidation.textareaValidation(value);

        case 'color':
            return FormValidation.emptyValidation(value);

        case 'select':
            return FormValidation.selectValidation(value);

        case 'multiselect':
            return FormValidation.multiselectValidation(value);

        case 'zipcode':
            return FormValidation.zipcodeValidation(value);
        
        case 'phone':
            return FormValidation.phoneValidation(value);

        case 'file':
            return FormValidation.fileValidation(value);
        
        case 'email':
            return FormValidation.emailValidation(value);

        case 'required_email':
            return FormValidation.emailRequiredValidation(value);

        case 'radio':
            return FormValidation.radioValidation(value);

        case 'single_checkbox':
            return FormValidation.checkboxValidation(value);

        case 'required_url':
            return FormValidation.urlRequiredValidation(value,length);

        case 'not_required_url':
            return FormValidation.urlValidation(value,length);

        case 'time':
            return FormValidation.timeValidation(value);

        case 'required_time':
            return FormValidation.timeRequiredValidation(value);

        case 'date':
            return FormValidation.dateValidation(value);

        case 'required_date':
            return FormValidation.dateRequiredValidation(value);

        case 'date_time':
            return FormValidation.dateTimeValidation(value);

        case 'required_date_time':
            return FormValidation.dateTimeRequiredValidation(value);

        default:
            return null;
    }
};