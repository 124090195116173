import React from 'react';
import ToggleSwitch from '../atoms/ToggleSwitch';
import Button from '../atoms/Button';
import { RiDeleteBinLine } from "react-icons/ri";
import { MdEdit } from "react-icons/md";


interface FAQItemProps {
    questionNumber: number | null;
    question: string;
    answer: string;
    status: boolean;
    onDelete?: () => void;
    handleToggle?: () => void;
    onEdit?: () => void;
}

const FaqItem: React.FC<FAQItemProps> = ({
    questionNumber,
    question,
    answer,
    status,
    onDelete,
    handleToggle,
    onEdit,
}) => {
    return (
        <div className="w-100 border p-3 mb-3">
            <div className='d-flex align-items-center justify-content-between'>
                <div className='d-flex'>
                    <span className="me-3">{questionNumber}.</span>
                    <h5 className="mb-0 color-blue me-2 text-break">{question}</h5>
                </div>
                <div className='d-flex'>
                    <ToggleSwitch
                        id="example-toggle"
                        name="exampleToggle"
                        checked={status}
                        onChange={handleToggle}
                        toggleclassName="my-toggle-class"
                    />
                    {onEdit && <Button className="btn fs-4" handleClick={onEdit} title={<MdEdit />} />}
                    {onDelete && (
                        <Button className="btn fs-4" handleClick={onDelete} title={<RiDeleteBinLine />} />
                    )}
                </div>
            </div>
            <div className="card-body">
                <div dangerouslySetInnerHTML={{ __html: answer }} />
            </div>
        </div>
    );
};

export default FaqItem;
