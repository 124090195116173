import React from "react";
import LabelField from "../atoms/LabelField";
import EditorComponent from "./CKEditor";
import { InputWithLabel } from "../atoms/InputWithLabel";
import InputTextfield from "../atoms/InputTextField";

export interface FormField {
  label: string;
  name: string;
  value: string;
  type: "text" | "editor";
  handleChange: any;
  error: string;
}

interface PopupFormProps {
  fields: FormField[];
}

const PopupForm: React.FC<PopupFormProps> = ({ fields }) => {
  return (
    <div className="container-fluid">
      {fields?.map((field, index) => (
        <div className="row mb-3" key={index}>
          <div className="col-lg-2 col-md-2 col-12">
            <LabelField
              title={`${field?.label}: `}
              className="d-flex"
              showAsterisk={true}
              mandatory={true}
            />
          </div>
          <div className="col-lg-10 col-md-10 col-12 mb-3">
            {field?.type === "text" ? (
              <div>
                <InputTextfield
                  type="text"
                  name={field?.name}
                  className={`w-100 ${field?.error ? "is-invalid" : ""}`}
                  value={field?.value}
                  handleChange={field?.handleChange}
                />
                {field?.error && (
                  <div className="invalid-feedback">{field?.error}</div>
                )}
              </div>
            ) : (
              <div>
                <EditorComponent
                  isLabel={false}
                  data={field?.value}
                  name={field?.name}
                  error={field?.error}
                  onChange={(data: string) => field?.handleChange(data)}
                />
                {field?.error && (
                  <div className="invalid-feedback">{field?.error}</div>
                )}
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default PopupForm;
