import React, { ReactNode, CSSProperties } from "react";

const style = {};

export interface Props {
  title: ReactNode;
  customStyle?: CSSProperties;
  mandatory?: boolean;
  showAsterisk?: boolean;
  className?: string;
  htmlforid?: string;
}

const LabelField: React.FC<Props> = ({
  title,
  customStyle = {},
  mandatory = false,
  showAsterisk = true,
  className = "",
  htmlforid = "",
}) => {
  return (
    <>
      <label
        style={{ ...style, ...customStyle }}
        className={`${className} text-break`}
        htmlFor={htmlforid}
      >
        {title}
        {mandatory && showAsterisk && <span className="text-danger"> *</span>}
      </label>
    </>
  );
};

export default LabelField;

//         className={`${
//   mandatory ? "custom_astrick " + className : className
// } text-break`}
