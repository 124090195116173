import React, { useEffect, useState } from "react";
import { filterValues } from "./State";
import { t } from "../../../../services/translation/TranslationUtils";
import Button from "../../../atoms/Button";
import { ManageUserFilterProps } from "../../users/Types";
import { renderInput } from "../../../../services/form/FormElements";
import ResetBtn from "../../../common/ResetBtn";

const ManageFilters: React.FC<ManageUserFilterProps> = ({
  handleChange,
  options,
  data,
  handleSubmit,
  handleClear
}) => {
  const [rows, setRows] = useState<any[]>([]);

  useEffect(() => {
    let row = filterValues(options);
    setRows(row);
  }, [options]);

  return (
    <>
      <div className="d-flex my-3 gap-4">
        {rows.map((value: any) => (
          <div className={`flex-fill w-25 manage_group`} key={value?.name}>
            {
              renderInput(() => { },
                value,
                handleChange,
                data,
              )
            }
          </div>
        ))}
        <div>
          <div className="d-flex align-items-center justify-content-end">
            <Button
              className="search_btn me-3"
              title={t('Search')}
              handleClick={handleSubmit}
            />
            <ResetBtn handleResetClick={handleClear} />
          </div>
        </div>
      </div>
    </>
  );
};

export default ManageFilters;
