export const SWITCH_TAB = "SWITCH_TAB";
export const SET_ERROR = "SET_ERROR";
export const UPDATE_FIELD = "UPDATE_FIELD";
export const UPDATE_INITIAL_DATA = "UPDATE_INITIAL_DATA";

export type FormAction =
  | {
      type: typeof UPDATE_FIELD;
      field?: string;
      value: any;
    }
  | {
      type: typeof SWITCH_TAB;
      value: any;
    }
  | {
      type: typeof SET_ERROR;
      field?: string;
      tab?: string;
      errors?: any;
      hasError?: boolean;
    }
  | {
      type: typeof UPDATE_INITIAL_DATA;
      value: any;
    };
