import { saveTranslation } from "../../routes/ApiEndpoints";
import { APICALL } from "../ApiServices";
import { selectAuth } from "../../features/auth/AuthSlice";

const get_targetkey = (key: string): string => {
    return key.split(' ').join('_').toLowerCase();
};

const lookupTranslations = (translations: any, key: string): any => {
    try {
        return translations && key in translations ? translations[key] : false;
    } catch (e) {
        console.error(e);
    }
    return undefined;
};

const checkDatabase = async (postdata: any, targetkey: any): Promise<string | undefined> => {
    try {
        let response = await APICALL.service(saveTranslation, 'POST', postdata);
        if (response?.status !== 200) {
            throw new Error('Request failed');
        }
        const translationData = response?.data[targetkey] ?? postdata?.string;
        // Update localStorage with the new translation
        updateLocalStorageTranslations(targetkey, translationData);
        return translationData;
    } catch (e) {
        console.error(e);
    }
};

const updateLocalStorageTranslations = (targetkey: string, translation: string): void => {
    try {
        let translations = JSON.parse(localStorage.getItem('translations') || '{}');
        translations[targetkey] = translation;
        localStorage.setItem('translations', JSON.stringify(translations));
        
    } catch (e) {
        console.error(e);
    }
};

export const t = (key: string): string => {
    const translations = JSON.parse(localStorage.getItem('translations') || '{}');
    const userLanguage = JSON.parse(JSON.parse(localStorage.getItem('persist:root') ?? '{}')?.auth ?? '{}')?.language;
    if (!key || key === "") return key;
    let targetkey = get_targetkey(key);
    let translation = lookupTranslations(translations, targetkey);
    if (!translation) {
        let postdata = {
            string: key,
            site_key: "backend",
            language_id: userLanguage // Use localStorage for language preference
        };
        checkDatabase(postdata, targetkey).then((translatedValue) => {
            translation = translatedValue || key;
        });
        return key; // Return the key temporarily
    }
    return translation || key;
};
