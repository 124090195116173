import React from "react";
import Button from "../../atoms/Button";
import FormPreview from "./FormPreview";
import FormFieldEditor from "./FormFieldEditor";
import {
  FormField,
  TabComponentProps,
} from "../../../pages/forms/context/Interfaces";
import { UPDATE_FIELD } from "../../../pages/forms/context/Action";

const FormBuilder: React.FC<TabComponentProps> = ({ state, dispatch }) => {
  const subSectionId = Number(
    state.options?.form_element_options?.find(
      (option) => option.label === "Sublevel"
    )?.value
  );
  const findFieldById = (fields: FormField[], id: string): FormField | null => {
    for (const field of fields) {
      if (field.key === id) return field;
      if (field.form_element === subSectionId && field.fields) {
        const found = findFieldById(field.fields, id);
        if (found) return found;
      }
    }
    return null;
  };

  const isSelectField = (id: number) => {
    const selectLabel = state?.options?.form_element_options?.find(
      (element) => element.value === id
    )?.label;
    return (
      selectLabel === "Radio Button group" || selectLabel === "Dropdown Select"
    );
  };

  const updateFieldsRecursively = (
    fields: FormField[],
    fieldId: string,
    updatedField: FormField
  ): FormField[] => {
    return fields.map((field) => {
      if (field.key === fieldId) return updatedField;
      if (field.form_element === subSectionId && field.fields) {
        return {
          ...field,
          fields: updateFieldsRecursively(field.fields, fieldId, updatedField),
        };
      }
      return field;
    });
  };

  const deleteFieldRecursively = (
    fields: FormField[],
    fieldId: string
  ): FormField[] => {
    return fields.filter((field) => {
      if (field.key === fieldId) return false;
      if (field.form_element === subSectionId && field.fields) {
        field.fields = deleteFieldRecursively(field.fields, fieldId);
      }
      return true;
    });
  };

  const addFieldToParent = (parent_id: string | null, newField: FormField) => {
    if (!parent_id) {
      dispatch({
        type: UPDATE_FIELD,
        field: "fields",
        value: [...state.layout.data.fields, newField],
      });
      return;
    }

    const updatedFields = state.layout.data.fields.map((field) => {
      if (field.key === parent_id && field.form_element === subSectionId) {
        return {
          ...field,
          fields: [...(field.fields || []), { ...newField, parent_id }],
        };
      }
      if (field.form_element === subSectionId && field.fields) {
        return {
          ...field,
          fields: field.fields.map((subField) => {
            if (subField.key === parent_id) {
              return {
                ...subField,
                fields: [
                  ...(subField.fields || []),
                  { ...newField, parent_id },
                ],
              };
            }
            return subField;
          }),
        };
      }
      return field;
    });

    dispatch({
      type: UPDATE_FIELD,
      field: "fields",
      value: updatedFields,
    });
  };

  const handleAddField = (parent_id: string | null = null) => {
    const maxOrder =
      state.layout.data.fields.length > 0
        ? Math.max(...state.layout.data.fields.map((field) => field.order || 0))
        : -1;

    const newField: FormField = {
      id: null,
      key: `field-${Date.now()}`,
      form_element: 1,
      title: "",
      required: false,
      parent_id: parent_id,
      order: maxOrder + 1,
    };

    dispatch({
      type: UPDATE_FIELD,
      field: "currentField",
      value: newField,
    });
    dispatch({
      type: UPDATE_FIELD,
      field: "editMode",
      value: { isEditing: true, fieldId: null },
    });
  };

  const handleEditField = (field: FormField) => {
    dispatch({
      type: UPDATE_FIELD,
      field: "currentField",
      value: field,
    });
    dispatch({
      type: UPDATE_FIELD,
      field: "editMode",
      value: { isEditing: true, fieldId: field.key },
    });
  };

  const handleSaveField = (field: FormField) => {
    if (state.layout.data.editMode.fieldId) {
      dispatch({
        type: UPDATE_FIELD,
        field: "fields",
        value: updateFieldsRecursively(
          state.layout.data.fields,
          state.layout.data.editMode.fieldId,
          field
        ),
      });
    } else {
      // Adding new field
      addFieldToParent(field.parent_id ?? null, field);
    }
    dispatch({
      type: UPDATE_FIELD,
      field: "currentField",
      value: null,
    });
    dispatch({
      type: UPDATE_FIELD,
      field: "editMode",
      value: { isEditing: false, fieldId: null },
    });
  };

  const handleDeleteField = (fieldId: string) => {
    dispatch({
      type: UPDATE_FIELD,
      field: "fields",
      value: deleteFieldRecursively(state.layout.data.fields, fieldId),
    });
  };

  const handleMoveField = (
    draggedId: string,
    targetId: string,
    position: "before" | "after" | "inside"
  ) => {
    const draggedField = findFieldById(state.layout.data.fields, draggedId);
    if (!draggedField) return;

    // First remove the dragged field
    let newFields = deleteFieldRecursively(state.layout.data.fields, draggedId);

    // Helper function to update the order of fields
    const updateOrder = (fields: FormField[]): FormField[] => {
      return fields.map((field, index) => ({
        ...field,
        order: index,
        fields: field.fields ? updateOrder(field.fields) : field.fields,
      }));
    };

    // Then insert it in the new position
    const insertField = (
      fields: FormField[],
      targetId: string,
      field: FormField,
      position: "before" | "after" | "inside"
    ): FormField[] => {
      if (position === "inside") {
        return fields.map((f) => {
          if (f.key === targetId) {
            return {
              ...f,
              fields: [...(f.fields ?? []), { ...field, parent_id: f.key }],
            };
          }
          if (f.form_element === subSectionId && f.fields) {
            return {
              ...f,
              fields: insertField(f.fields, targetId, field, position),
            };
          }
          return f;
        });
      }

      const targetIndex = fields.findIndex((f) => f.key === targetId);
      if (targetIndex !== -1) {
        const newFields = [...fields];
        newFields.splice(
          position === "before" ? targetIndex : targetIndex + 1,
          0,
          {
            ...field,
            parent_id: fields[targetIndex].parent_id,
          }
        );
        return newFields;
      }

      return fields.map((f) => {
        if (f.form_element === subSectionId && f.fields) {
          return {
            ...f,
            fields: insertField(f.fields, targetId, field, position),
          };
        }
        return f;
      });
    };

    newFields = insertField(newFields, targetId, draggedField, position);
    // Update the order of all fields after insertion
    newFields = updateOrder(newFields);

    console.log(newFields);
    dispatch({
      type: UPDATE_FIELD,
      field: "fields",
      value: newFields,
    });
  };

  return (
    <div className="row">
      {/* Left side - Form Preview */}
      <div className="col-6">
        <FormPreview
          isSelectField={isSelectField}
          subSectionId={subSectionId}
          fields={state.layout.data.fields}
          onEditField={handleEditField}
          onDeleteField={handleDeleteField}
          onMoveField={handleMoveField}
          onAddSubField={(parent_id) => handleAddField(parent_id)}
        />
      </div>

      {/* Right side - Field Editor */}
      <div className="col-6">
        {state.layout.data.editMode.isEditing &&
        state.layout.data.currentField ? (
          <FormFieldEditor
            subSectionId={subSectionId}
            isSelectField={isSelectField}
            formelements={state.options.form_element_options}
            field={state.layout.data.currentField}
            onSave={handleSaveField}
            onCancel={() => {
              dispatch({
                type: UPDATE_FIELD,
                field: "currentField",
                value: null,
              });
              dispatch({
                type: UPDATE_FIELD,
                field: "editMode",
                value: { isEditing: false, fieldId: null },
              });
            }}
          />
        ) : (
          <div className="p-4 border h-100">
            <h2 className="Title-forms mb-4">Field Editor</h2>
            <Button
              className="create_button"
              handleClick={() => handleAddField(null)}
            >
              Add New Field
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default FormBuilder;
