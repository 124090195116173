import React, { ChangeEvent, useEffect, useState } from "react";
import { APICALL } from "../../../services/ApiServices";
import { Forms } from "../../../routes/ApiEndpoints";
import Popup from "../../molecules/Popup";
import TableStructure from "../../atoms/TableStructure";
import { formViewHeaders, formViewValues, manageFormState } from "../../../pages/forms/context/State";
import { formViewInitialState } from "./State";
import FormViewLayout from "./FormViewLayout";
import Pagination from "../../molecules/Paginations";
import ManageFormDataFilters from "./ManageFormDataFilter";


const ManageFormDataOrganism: React.FC = () => {
    const searchParams = new URLSearchParams(window.location.search);
    const formId = searchParams.get("id");
    const [ state, setState ] = useState(manageFormState);
    const [formState, setFormState] = useState(formViewInitialState);

    useEffect(() => {
        fetchData(state.postData);
      }, []);
    
    const fetchData = async (postData:any) => {
        try {
          let formUrl
          if (formId) {
            formUrl = "/fetch-form/" + formId;
          }else{
            formUrl = "/fetch-form";
          }
            const response = await APICALL.service(Forms + formUrl, "POST",postData);
            if (response?.data) {
                setState((prevState: any) => ({
                  ...prevState,
                  data: response.data.formData,
                  options:{
                    title:response.data.titles,
                    type:response.data.types
                  },
                  postData: {
                    ...prevState.postData,
                    total_pages: response.data.total_pages,
                  },
                }));
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };

      const handleClear = () => {
          setState((prevState: any) => ({
            ...prevState,
            filters:manageFormState.filters,
            postData: manageFormState.postData,
          }));
          fetchData(manageFormState.postData);
        };

      const handleView = async (value: any) => {
        try {
              if (value.form_id) {
                const response1 = await APICALL.service(Forms + "/fetch-data/" + value.form_id, "POST",{batch:value["batch"]});             
                if (response1?.data) {
                  let formChangeData = generateInitialState(response1?.data?.title, response1?.data?.data)
                  setFormState(formChangeData)
                }
              }
            } catch (error) {
              console.error("Error fetching data:", error);
            }
        setState((prevState: any) => ({
          ...prevState,
          popup: true,
        }));
      };

      const initialStateForSubFields = (fields:any,initialState:any) =>{
        fields.forEach((field:any) => {
          const { name,type} = field;
          if(type==='single_checkbox'){
            initialState.form[name] = field.value ? field.value : false;
          }else if(type === 'upload_file'){
            initialState.form[name] =field.value ? field.value : null;
          }else if(type === 'sublevel'){
            initialStateForSubFields(field.fields,initialState);
          }else if(type !== "h1" && type !=='h2' && type !=='h3' && type !== 'hr'){
            initialState.form[name] = field.value ? field.value : "";
          }
        });
        return initialState
      }
    
      const generateInitialState = (title:string,fields: any[]) => {
        const initialState: any = {
          title:title ?? "",
          state:[],
          form:{},
          errors:{}
        };
        fields.forEach(field => {
          initialState.state.push(field)
        });
        return initialStateForSubFields(fields,initialState);
      };

      const handleSort = () => {
        let value = state.postData.order === "DESC" ? "ASC" : "DESC";
        let postData = {
          ...state.postData,
          order_type: "created_at",
          order: value,
        };
        setState((prevState: any) => ({
          ...prevState,
          postData: postData,
        }));
        fetchData(postData);
      };
    
      const handlePaginationClick = (pageNumber: number) => {
        let postData = {
          ...state.postData,
          current_page: pageNumber,
        };
        setState((prevState: any) => ({
          ...prevState,
          postData: postData,
        }));
        fetchData(postData);
      };

      const handleChange = (
          e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
          field: string
        ) => {
          setState((prevState: any) => ({
            ...prevState,
            postData:{
              ...prevState.postData,
              [field]: e?.target?.value ?? e,
            },
            filters: {
              ...prevState.filters,
              [field]: e?.target?.value ?? e,
            },
          }));
        };

    const layout= <FormViewLayout
      title={formState.title}
      values = {formState.state}
      data = {formState.form}
    />;

    return (
      <>
      <div className="search-bar py-3">
        <div className="row pb-3">
        </div>
        <ManageFormDataFilters
          handleChange={handleChange}
          options={state?.options}
          data={state?.filters}
          handleSubmit={() => fetchData(state.postData)}
          handleClear={handleClear}
        />
      </div>

      <TableStructure
        isAction
        data={state.data}
        headers={formViewHeaders}
        values={formViewValues}
        handleView={handleView}
        handleSort={handleSort}
      />
      {state?.postData?.total_pages > 1 && (
        <Pagination
          currentPage={state?.postData?.current_page}
          totalPages={state?.postData?.total_pages}
          handlePaginationClick={handlePaginationClick}
        />
      )}
      {state?.popup && (
        <Popup
          body={layout}
          cancel={() =>
            setState((prevState: any) => ({
              ...prevState,
              popup: false,
            }))
          }
        />
      )}
    </>
    )
}
export default ManageFormDataOrganism;