import React, { useEffect } from "react";
import { APICALL } from "../../../services/ApiServices";
import ActionButtonGroup from "../../molecules/ActionButtonGroup";
import {
  dynamicSubmitAndBackFunction,
  handleTabChange,
} from "../../../services/HandleTabServices";
import { useNavigate } from "react-router-dom";
import CustomNotify from "../../atoms/CustomNotify";
import FormValidation from "../../../services/formvalidation/Validation";
import { t } from "../../../services/translation/TranslationUtils";
import TitleAtom from "../../common/TitleAtom";
import {
  FormStateTabs,
  OptionsList,
  TabComponentProps,
} from "../../../pages/forms/context/Interfaces";
import GeneralDetailsTab from "./GeneralDetailsTab";
import AdministratorsTab from "./Administratorstab";
import {
  useFormDispatch,
  useFormState,
} from "../../../pages/forms/context/FormContext";
import { getFormFields } from "../../../pages/forms/context/State";
import {
  SET_ERROR,
  UPDATE_FIELD,
  UPDATE_INITIAL_DATA,
} from "../../../pages/forms/context/Action";
import { Forms, FormsField } from "../../../routes/ApiEndpoints";
import FormBuilder from "../../templates/forms/FormBuilder";
import Button from "../../atoms/Button";

const tabComponents: {
  [key: string]: React.ComponentType<TabComponentProps>;
} = {
  general_details: GeneralDetailsTab,
  layout: FormBuilder,
  administrators: AdministratorsTab,
};

const CreateFormOrganism: React.FC = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const editId = searchParams.get("edit");
  const cloneId = searchParams.get("clone");
  const state = useFormState();
  const dispatch = useFormDispatch();
  const formConfig: any = getFormFields(state);
  const navigate = useNavigate();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await APICALL.service(FormsField, "GET");
      if (response?.data) {
        let options = {
          manager_options: response.data.userOptions ?? [],
          user_options: response.data.userOptions ?? [],
          team_options: response.data.groupOptions ?? [],
          admin_options: response.data.userOptions ?? [],
          formType_options: response.data.form_types ?? [],
          form_element_options: response.data.form_elements ?? [],
          date_format_options: response.data.date_format ?? [],
          time_format_options: response.data.time_format ?? [],
          field_action_options: response.data.field_actions ?? [],
        };
        if (editId) {
          fetchForm(options);
        } else {
          dispatch({
            type: UPDATE_FIELD,
            field: "options",
            value: options,
          });
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchForm = async (OptionsList: OptionsList) => {
    const response = await APICALL.service(Forms + "/" + editId, "GET");

    const { admin, manager, user, team } = response.data;
    let updatedData = {
      options: OptionsList,
      data: {
        title: response.data.title,
        form_type: response.data.form_type,
        isActive: response.data.status,
        anonymous: response.data.form_info.anonymous,
        disable_result_export: response.data.form_info.disable_result_export,
        has_expiration_date: response.data.form_info.has_expiration_date,
        private_form: response.data.form_info.private_form,
        public: response.data.form_info.public,
        record_on_user_profile: response.data.form_info.record_on_user_profile,
        show_in_library: response.data.form_info.show_in_library,
        show_results_to_user: response.data.form_info.show_results_to_user,
        single_completion: response.data.form_info.single_completion,
        fields: response.data.fields,
        admin,
        manager,
        user,
        team,
      },
    };
    dispatch({
      type: UPDATE_INITIAL_DATA,
      value: updatedData,
    });
  };

  const CurrentTabComponent = tabComponents[state.currentTab];

  const validate = (field?: string) => {
    if (field) {
      dispatch({ type: SET_ERROR, field });
    } else {
      const allTabsErrors = validateAllTabs();
      if (
        Object.values(allTabsErrors)?.some((tabErrors) => tabErrors.hasError)
      ) {
        for (const tab in allTabsErrors) {
          dispatch({
            type: SET_ERROR,
            tab,
            errors: allTabsErrors[tab].errors,
            hasError: allTabsErrors[tab].hasError,
          });
        }
      } else {
        handleSubmit();
      }
    }
  };
  const handleSubmit = async () => {
    const title = state?.general_details?.data?.title || "";
    const key = title.toLowerCase().replace(/\s+/g, "_");
    const form_type = state?.general_details?.data?.form_type;

    const formInfoKeys = [
      "private_form",
      "record_on_user_profile",
      "disable_result_export",
      "anonymous",
      "show_in_library",
      "public",
      "single_completion",
      "has_expiration_date",
      "show_results_to_user",
    ] as const;

    type GeneralDetailsData = typeof state.general_details.data;

    const form_info = formInfoKeys.reduce<Record<string, boolean>>(
      (acc, key) => {
        acc[key] =
          !!state.general_details.data[key as keyof GeneralDetailsData];
        return acc;
      },
      {}
    );

    const postdata = {
      title: state?.general_details?.data?.title,
      form_type,
      key,
      status: state?.general_details?.data?.isActive,
      form_info,
      ...state?.administrators?.data,
      fields: state.layout.data.fields,
    };

    try {
      let response;
      if (editId) {
        const postdata = {
          title: state?.general_details?.data?.title,
          form_type,
          status: state?.general_details?.data?.isActive,
          form_info,
          ...state?.administrators?.data,
          fields: state.layout.data.fields,
        };
        response = await APICALL.service(Forms + "/" + editId, "PUT", postdata);
      } else {
        response = await APICALL.service(Forms, "POST", postdata);
      }

      !cloneId &&
        CustomNotify({
          type: response.status === 200 ? "success" : "error",
          message: response.message,
        });

      if (response.status === 200) {
        navigate("/forms/manage");
      }

      if (response?.status === 422 && response?.errors?.title) {
        let errors: any = {
          ...state["general_details"]?.errors,
          title: response?.errors?.title?.[0] ?? "The title already exists",
        };
        dispatch({
          type: SET_ERROR,
          tab: "general_details",
          errors: errors,
          hasError: true,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const validateAllTabs = () => {
    const allTabsErrors: Record<string, { errors: any; hasError: boolean }> =
      {};
    state.tabs.forEach((tab: any) => {
      const tabid: keyof FormStateTabs = tab.id;
      const tabFields = getFormFields({ ...state, currentTab: tab.id });
      const formdata: any = state[tabid]?.data;
      const errors: Record<string, string> = {};
      tabFields.forEach((fieldGroup: any) => {
        Object.values(fieldGroup).forEach((field: any) => {
          if (field.required) {
            if (field.type === 'text') {
              errors[field.name] = FormValidation?.nameValidation(
                formdata[field?.name] ?? "",
                125
              );
            } else {
              errors[field.name] = FormValidation?.multiselectValidation(
                formdata[field?.name] ?? ""
              );
            }
          }
        });
      });
      allTabsErrors[tab.id] = {
        errors,
        hasError: Object.values(errors)?.some((value) => value?.length > 0),
      };
    });
    return allTabsErrors;
  };

  const handleChange = (e: any, field: string) => {
    let value: any;
    if (e === null || e.length === 0) {
      value = null;
    } else if (typeof e === "object" && !e.target) {
      value = e.value ?? e;
    } else if (e.target?.type === "checkbox") {
      value = e.target.checked;
    } else if (Array.isArray(e.target?.value)) {
      value = e.target.value;
    } else if (e.target?.value !== undefined) {
      value = e.target.value;
    } else {
      console.error("Unrecognized event structure:", e);
      return;
    }
    dispatch({ type: UPDATE_FIELD, field, value });
  };

  return (
    <>
      <div className="search-bar py-3">
        <TitleAtom title={t("Create Form")} />
      </div>
      <div className="tabs-container createGroupTabs">
        {state.tabs.map((tab, index) => (
          <Button
            key={tab.id}
            handleClick={() =>
              handleTabChange(state, dispatch, "switch", index)
            }
            className={`tab-button ${state.currentTab === tab.id ? "active" : ""
              } ${tab?.error ? " border-danger" : ""} mx-0`}
            title={t(tab.title)}
          />
        ))}
      </div>

      <div className="tab-content">
        {formConfig && CurrentTabComponent && (
          <CurrentTabComponent
            state={state}
            dispatch={dispatch}
            handleChange={handleChange}
            formConfig={formConfig}
            removeError={validate}
          />
        )}
      </div>

      <ActionButtonGroup
        maindivcss="navigation-buttons"
        backbuttoncss="back_btn"
        savebuttoncss="create_button"
        backTitle={
          dynamicSubmitAndBackFunction(state, "back")
            ? t("Back")
            : t("Previous")
        }
        saveAndNextTitle={
          dynamicSubmitAndBackFunction(state, "save") ? t("Save") : t("Next")
        }
        handleBackClick={() =>
          dynamicSubmitAndBackFunction(state, "back")
            ? navigate("/forms/manage")
            : handleTabChange(state, dispatch, "previous")
        }
        handleSaveAndNextClick={() =>
          dynamicSubmitAndBackFunction(state, "save")
            ? validate()
            : handleTabChange(state, dispatch, "next")
        }
      />
    </>
  );
};
export default CreateFormOrganism;
