export const formInitialState = {
    form:{},
    validateForm: [],
    saving:false,
    errors:{}
}

export const formViewInitialState = {
    title:"",
    state:[],
    form:{},
    errors:{}
}

export const filterManageValues = (options:any) => {
    let row = [
        {
            name: 'title',
            type: 'single-select-without-label',
            placeholder: 'Select title',
            value: "",
            options: options?.title,
        },
        {
            name: 'type',
            type: 'single-select-without-label',
            placeholder: 'Select type',
            value: "",
            options: options?.type,
        },
        {
            name: 'user',
            class: {
                mainclass: 'h-100',
                feildclass: 'h-100'
            },
            type: 'text',
            placeholder: 'Search by user name',
            value: "",
            label: true
        },
    ]
    return row;
}

export const filterManageFormValues = (options:any) => {
    let row = [
        {
            name: 'form_type',
            type: 'single-select-without-label',
            placeholder: 'Select type',
            value: "",
            options: options,
        },
        {
            name: 'search',
            class: {
                mainclass: 'h-100',
                feildclass: 'h-100'
            },
            type: 'text',
            placeholder: 'Search by title',
            value: "",
            label: true
        },
    ]
    return row;
}
