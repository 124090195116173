import React, { useState, useRef, useEffect } from 'react';

const style = {};

interface Props {
    id?: string;
    type?: string;
    className?: string;
    value?: string;
    isDisabled?: boolean;
    placeholder?: string;
    customStyle?: React.CSSProperties;
    handleChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleClick?: (event: React.FocusEvent<HTMLInputElement>) => void;
    handleKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
    handleBlur?: () => void;
    name?: string;
    error?: string;
    readOnly?: boolean;
    autoComplete?: boolean;
    inputRef?: React.RefObject<HTMLInputElement> | null;
    autoFocus?: boolean;
}

const InputTextfield: React.FC<Props> = ({
    id = '',
    type = 'text',
    className = '',
    value = '',
    isDisabled = false,
    placeholder = '',
    customStyle = {},
    handleChange,
    handleClick,
    name = '',
    error = '',
    readOnly = false,
    handleKeyDown,
    autoComplete,
    handleBlur,
    inputRef = null,
    autoFocus = false,
}) => {
    const [isFocused, setIsFocused] = useState(false);
    const defaultRef = useRef<HTMLInputElement>(null);
    const resolvedRef = inputRef || defaultRef;

    useEffect(() => {
        const handleContextMenu = (event: MouseEvent) => {
            event.preventDefault();
        };
        const handleInspect = () => {
            if (isDisabled || readOnly) {
                resolvedRef.current?.blur();
            }
        };

        document.addEventListener('contextmenu', handleContextMenu);
        document.addEventListener('keydown', handleInspect);

        return () => {
            document.removeEventListener('contextmenu', handleContextMenu);
            document.removeEventListener('keydown', handleInspect);
        };
    }, [isDisabled, readOnly, resolvedRef]);

    return (
        <>
        <input
            style={{ ...style, ...customStyle }}
            id={id}
            type={type}
            className={`${className} ${readOnly ? 'input-readonly' : ''} form-control`}
            disabled={isDisabled}
            placeholder={isFocused ? '' : placeholder}
            name={name}
            value={value}
            onChange={handleChange}
            onFocus={handleClick}
            onBlur={handleBlur}
            readOnly={readOnly}
            onKeyDown={handleKeyDown}
            autoComplete={autoComplete ? '' : 'off'}
            ref={inputRef ?? resolvedRef}
            autoFocus={autoFocus}
        />
        {error && <span className="text-danger mt-2 fs-6">{error}</span>}
        </>
    );
};

export default InputTextfield;
