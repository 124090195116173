import React, { ChangeEvent, useEffect, useState } from "react";
import { APICALL } from "../../../services/ApiServices";
import { OptionsPayload, initialstate } from "./State";
import { createAlerts, fetchOptions, manageAlerts } from "../../../routes/ApiEndpoints";
import { useNavigate } from "react-router-dom";
import ActionButtonGroup from "../../molecules/ActionButtonGroup";
import CustomNotify from "../../atoms/CustomNotify";
import FormValidation from "../../../services/formvalidation/Validation";
import { Files } from "../../common/CommonInterfaces";
import { t } from "../../../services/translation/TranslationUtils";
import CreateAlertForm from "./CreateAlertForm";
import CommonServices from "../../../services/CommonService";
import TitleAtom from "../../common/TitleAtom";
import { mimeTypeMapping } from "../../../utils/constant";

const CreateAlertOrganism: React.FC = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const editId = searchParams.get("edit");
  const [state, setState] = useState(initialstate);
  const navigate = useNavigate();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      let postdata: any = {
        initialCall: true,
        alert_id: Number(editId)
      }
      if (!editId) postdata = OptionsPayload;
      const response = await APICALL.service((!editId ? fetchOptions : manageAlerts), 'POST', postdata);

      if ([200, 201].includes(response?.status)) {
        let data: any = {};
        if (editId) {
          data = {
            ...state,
            options: response?.data?.options ?? [],
            form: {
              ...state?.form,
              ...response?.data?.data
            }
          }
        } else {
          data = {
            ...state,
            options: response?.data ?? []
          }
        }
        setState((prevState: any) => ({ ...prevState, ...data }));
      }
    } catch (error) {
      console.log(error);
    }
  }

  const removeError = (fieldName: any) => {
    setState((prevState: any) => ({ ...prevState, errors: { ...prevState?.errors, [fieldName]: "" } }));
  };

  const handleFileOperation = (file: File, field: any) => {
    if (file) {
      const validFileExtensions = field.accept.split(',').map((ext: string) => ext.trim());
      const validMimeTypes = validFileExtensions.map((ext: any) => mimeTypeMapping[ext]);
      if (!validMimeTypes?.includes(file.type)) {
        alert(`Invalid file type. Please upload a file of type: ${field.accept}`);
        return;
      }
      // Set size limits based on file type
      let maxSize = 2 * 1024 * 1024; //2MB for images
      if (file.size > maxSize) {
        alert(`File size exceeds the limit of ${maxSize / (1024 * 1024)}MB. Please upload a smaller file.`);
        return;
      }
      const newFile: Files = {
        file,
        file_path: file && URL.createObjectURL(file),
        file_name: file?.name,
      };
      setState((prevState: any) => ({
        ...prevState,
        form: {
          ...prevState.form,
          [field.name]: newFile
        }
      }));
    }
  };

  const handleChange = (e: any, field: any) => {
    if (field?.type === 'file') {
      handleFileOperation(e, field);
    } else {
      let value = e?.target?.value ?? e;
      setState((prevState: any) => ({
        ...prevState,
        form: {
          ...prevState.form,
          [field?.name]: value,
        },
      }));
    }
  };

  const validate = () => {
    let texterror = FormValidation.nameValidation(state?.form?.text ?? "");
    let userserror = FormValidation.multiselectValidation(state?.form?.users ?? "");
    let groupserror = FormValidation.multiselectValidation(state?.form?.groups ?? "");
    let dateeror = FormValidation.emptyValidation(state?.form?.expired_at);
    userserror = (userserror != '' && groupserror != '') ? userserror : "";
    setState((prevState: any) => ({ ...prevState, errors: { ...prevState?.errors, text: texterror, users: userserror, expired_at: dateeror } }));
    return (texterror == "" && (userserror == "" || groupserror == "") && dateeror == "");
  }

  const handleSubmit = async () => {
    let validated = validate();
    if (validated) {
      try {
        // Append other data
        const data = {
          alert_id: editId,
          ...state?.form,
          expired_at: CommonServices?.convertToISODate(state?.form?.expired_at)
        };
        const response = await APICALL.service(createAlerts, 'POST', data);
        CustomNotify({ type: response.status === 200 ? "success" : "error", message: response.message });
        if (response?.status === 200) {
          navigate('/alerts/manage')
        }
      } catch (error) {
        CustomNotify({ type: "error", message: "Error while creating news" });
      }
    }
  };

  return (
    (state &&
      <>
        <div className="search-bar py-3">
          <TitleAtom title={t("Create alert")} />
        </div>
        <div className="form p-4">
          <CreateAlertForm
            data={state?.form}
            errors={state?.errors}
            handleChange={handleChange}
            removeError={removeError}
            options={state?.options}
          />
        </div>
        <ActionButtonGroup
          maindivcss="navigation-buttons"
          backTitle={t('Back')}
          saveAndNextTitle={t('Save')}
          handleBackClick={() => navigate('/alerts/manage')}
          handleSaveAndNextClick={handleSubmit}
        />
      </>
    )
  );
};

export default CreateAlertOrganism;
