import React, { useEffect, useState } from "react";
import Button from "../../atoms/Button";
import { InputWithLabel } from "../../atoms/InputWithLabel";
import MultiSelect from "../../atoms/MultiSelectField";
import ToggleSwitch from "../../atoms/ToggleSwitch";
import { FormField } from "../../../pages/forms/context/Interfaces";
import { Option } from "../../common/CommonInterfaces";
import FormValidation from "../../../services/formvalidation/Validation";

const FormFieldEditor: React.FC<{
  subSectionId: number | null;
  formelements: Option[];
  isSelectField: (id: number) => boolean;
  field: FormField;
  onSave: (field: FormField) => void;
  onCancel: () => void;
}> = ({ subSectionId, field, formelements, onSave, onCancel, isSelectField }) => {
  const [editedField, setEditedField] = useState<FormField>(field);
  const [error, setError] = useState({
    title: '',
    options: ''
  });

  useEffect(() => {
    setEditedField(field);
  }, [field]);

  const handleSave = (editedField: FormField) => {
    let error = FormValidation.nameValidation(editedField.title);
    const hasOptionError = editedField.options?.some(option => FormValidation.nameValidation(option.label) !== "");
    let optionError = hasOptionError ? 'One or more options are invalid' : '';

    if (error === '' && optionError === '') {
      onSave(editedField);
    }
    setError((prevState) => ({
      ...prevState,
      title: error,
      options: optionError
    }));
  }

  return (
    <div className="p-4 border h-100">
      <h3 className="text-lg font-semibold mb-4">
        {field.key ? 'Edit Field' : 'Add New Field'}
      </h3>

      <div className="space-y-4">
        <MultiSelect
          name="type"
          options={formelements}
          handleChange={(e) => {
            const newType = e.value as FormField['form_element'];
            setEditedField({
              ...editedField,
              form_element: newType,
              fields: newType === subSectionId ? [] : undefined,
              options: isSelectField(newType) ? [] : undefined
            });
          }}
          isSearchable
          isClearable={false}
          standards={formelements.find(item => item.value === editedField.form_element)}
        />

        <InputWithLabel
          error={error.title}
          value={editedField.title}
          mainclass="w-full mb-3"
          handleChange={(e) => setEditedField({ ...editedField, title: e.target.value })}
          placeholder="Field label"
        />

        <div className="flex items-center gap-2">
          <ToggleSwitch
            id="required"
            name="required"
            title="Required"
            checked={editedField.required}
            onChange={(e) => setEditedField({ ...editedField, required: e.target.checked })}
          />
        </div>
        {isSelectField(editedField.form_element) && (
          <div className="space-y-2">
            <h4 className="font-medium">Options</h4>
            {error.options && <span className="text-danger mt-2 fs-6">{error.options}</span>}
            {(editedField.options || []).map((option, index) => (
              <div key={index} className="d-flex gap-2 align-items-end">
                <InputWithLabel
                  value={option.label}
                  mainclass="flex-1"
                  handleChange={(e) => {
                    const newOptions = [...(editedField.options ?? [])];
                    newOptions[index].label = e.target.value;
                    setEditedField({ ...editedField, options: newOptions });
                  }}
                />
                <Button
                  className="create_button"
                  handleClick={() => {
                    const newOptions = [...(editedField.options ?? [])];
                    if (index > 0) {
                      // Swap with the previous option
                      const tempOrder = newOptions[index].order;
                      newOptions[index].order = newOptions[index - 1].order;
                      newOptions[index - 1].order = tempOrder;
                    }
                    newOptions.splice(index, 1);
                    setEditedField({ ...editedField, options: newOptions });
                  }}
                >
                  x
                </Button>
              </div>
            ))}
            <Button
              className="create_button mt-2"
              handleClick={() => {
                // Check if the last option has a non-empty label
                const lastOption = editedField.options?.[editedField.options.length - 1];
                if (!lastOption || lastOption?.label?.trim() !== '') {
                  const newOptions = [
                    ...(editedField.options ?? []),
                    {
                      value: `${Date.now()}`,
                      label: '',
                      order: (editedField.options?.length ?? 0) + 1, // Assign the next order
                    },
                  ];
                  setEditedField({ ...editedField, options: newOptions });
                }
              }}
            >
              Add Option
            </Button>
          </div>
        )}

        <div className="d-flex justify-content-between mt-4">
          <Button
            className="create_button"
            handleClick={onCancel}
          >
            Cancel
          </Button>
          <Button
            className="create_button"
            handleClick={() => handleSave(editedField)}
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  );
};

export default FormFieldEditor;
