import { renderInput } from "../../../services/form/FormElements";
import Button from "../../atoms/Button";
import {t} from "../../../services/translation/TranslationUtils";
import { inputValidation } from "../../../services/formvalidation/ValidationTypes";
import { useNavigate } from "react-router-dom";
import ViewPreviewFile from "../../molecules/ViewPreviewFile";

interface FormViewProps {
  removeError: (fieldName: string,key?:any) => void;
  handleChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    field: string
  ) => void;
  handleDateFormat?: (dateValue: string, field: string) => void;
  values: any;
  validateForm: any;
  data?: any;
  errors?: any;
  handleSubmit: any;
  saving?: boolean;
}

const Layout: React.FC<FormViewProps> = ({
    removeError,
    handleDateFormat,
    handleChange,
    values,
    validateForm,
    data,
    errors,
    handleSubmit,
    saving = false,
  }) => {
    const navigate = useNavigate()
    const validate = () => {
      if (!saving) {
        const error: any = {};
        validateForm.forEach((element: any) => {
          if (errors.hasOwnProperty(element.name)) {
            error[element.name] = inputValidation(data[element.name], element.validation)
          }
        })
        const valid = Object.values(error)?.every(message => message === '' || message === null);
        handleSubmit(error, valid);
      }
    };

    const backToForms = () =>{
      navigate('/forms/manage')
    }

    const SaveButton = values.length > 0 ? <Button
      className="create_button"
      handleClick={validate}
      title={saving ? t("Saving...") : t("Save")}
      /> : <span></span>;
  
    return (
      <div className="form overflow-auto d-flex flex-column align-items-center my-4 px-4 py-4 w-100 form-layout">
        {values.length>0 &&<div className="d-flex flex-column gap-3 w-100">
                    {Object.values(values).map((value: any) => (
                      <div className={`flex-1 w-100`} key={value?.title}>
                        {
                          renderInput(
                            removeError,
                            value,
                            value?.type == 'date' ? handleDateFormat : handleChange,
                            data,
                            errors,
                            ()=>{},
                            handleDateFormat,
                          )
                        }
                        <div className="d-flex">
                        {
                          Object.entries(data).map(([key1,values])=>{
                            if(value?.name===key1){
                              if (values !== null && typeof values === 'object' && 'file_path' in values && values?.file_path && values?.file_path != '') {
                                return (
                                  <div className="flex-1" key={key1}>
                                      <ViewPreviewFile
                                          key={key1}
                                          fileData={values}
                                          onDeleteFile={(file_path) => removeError(file_path, key1)}
                                      />
                                  </div>
                              );
                            }
                            }
                            return null
                          })
                        }
                    </div>
                      </div>
                    ))}
                  </div>}
        <div className="d-flex justify-content-center  w-100 mt-3">
        {SaveButton}
        </div>
      </div>
    );
};
export default Layout;
