import { isValidPhoneNumber } from "react-phone-number-input";

const FormValidation = {
    nameValidation: function (value: any, length = 255, required = true, fieldtitle = 'The') {
        //For required text fields
        if (((value?.toString()?.trim()) == "" || value == null) && required) {
            return `${fieldtitle} field is required`;
        } else if (value?.toString()?.trim()?.length > length) {
            return `${fieldtitle} field length exceeds ${length} characters`;
        } else {
            return "";
        }
    },
    textValidation: function (value: any, length = 255) {
        //For not required fields
        if (value?.trim()?.length > length) {
            return `The field length exceeds ${length} characters`;
        } else {
            return "";
        }
    },
    zipcodeValidation: function (value: any) {
        //For not required fields
        if (value?.trim()?.length > 20) {
            return "The field length exceeds 20 characters";
        } else {
            return "";
        }
    },
    numberValidation: function (value: any) {
        //only for range atom
        if (value == "") {
            return "This field is required";
        } else if (value > 0 && value <= 100) {
            return "";
        } else {
            return "The field accepts only (1 - 100) numbers";
        }
    },
    isNumberValidation: function (value: any, min: any, max: any) {
        const onlyNumbersRegex = /^[0-9]+$/;

        if (!onlyNumbersRegex.test(value)) {
            return "Invalid input format";
        } else if (Number(value) < Number(min)) {
            return `Minimum value is ${min}`;
        } else if (Number(value) > Number(max)) {
            return `maximum value is ${max}`;
        } else {
            return "";
        }
    },
    isDecimalValidation: function (value: any, min: any, max: any) {
        const decimalNumberRegex = /^\d*\.?\d*$/;

        if (!decimalNumberRegex.test(value)) {
            return "Invalid input format";
        } else if (value < min) {
            return `Minimum value is ${min}`;
        } else if (value > max) {
            return `maximum value is ${max}`;
        } else {
            return "";
        }
    },
    textareaValidation: function (value: any) {
        if (value?.trim() == "") {
            return "This field is required";
        } else {
            return "";
        }
    },
    checkboxValidation: function (value: any) {
        if (!value) {
            return "This field is required";
        } else {
            return "";
        }
    },
    radioValidation: function (value: any) {
        if (value == "") {
            return "This field is required";
        } else {
            return "";
        }
    },
    emptyValidation: function (value: any) {
        if (value == "" || value == null) {
            return "This field is required";
        } else {
            return "";
        }
    },
    selectValidation: function (value: any) {
        if (value == null || value.length == 0) {
            return "This field is required";
        } else {
            return "";
        }
    },
    multiselectValidation: function (value: any) {
        // For required fields
        if (value.length == 0) {
            return "This field is required";
        } else {
            return "";
        }
    },
    fileValidation: function (value: any) {
        const isEmptyFileObject = (
            value &&
            typeof value === "object" &&
            "file" in value &&
            "file_path" in value &&
            "file_name" in value &&
            Array.isArray((value as any).file) &&
            Array.isArray((value as any).file_path) &&
            Array.isArray((value as any).file_name) &&
            (value as any).file.length === 0 &&
            (value as any).file_path.length === 0 &&
            (value as any).file_name.length === 0
        );
        //For not required fields
        if (value == null || value == '' || isEmptyFileObject) {
            return "This field is required";
        } else {
            return "";
        }
    },
    dateValidation: function (date: string, regex = /^\d{4}-\d{2}-\d{2}$/, fieldtitle = 'Date') {
        if (!regex.test(date) && date != null && date != '') {
            return `${fieldtitle} format is incorrect`
        } else {
            return ""
        }
    },
    isValidHexColor: (color: string) => {
        if (!color) return "";
        const hexColorRegex = /^#[0-9a-fA-F]{6}$/;
        return hexColorRegex.test(color) ? "" : "Invalid hex code"
    },
    hasDuplicates: (array: any) => {
        return new Set(array).size !== array.length;
    },
    phoneValidation: (value: string) => {
        if (value == "" || value == null) {
            return "This field is required";
        } else if (!isValidPhoneNumber(value)) {
            return "Phone number is not valid";
        } else {
            return "";
        }
    },
    emailValidation: (value: string) => {
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

        if (value && !emailRegex.test(value.toLowerCase())) {
            return "Email is not valid";
        } else {
            return "";
        }
    },
    emailRequiredValidation: function (value: any,  required = true) {
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (((value?.toString()?.trim()) == "" || value == null) && required) {
            return `Email field is required`;
        } else if (value && !emailRegex.test(value.toLowerCase())) {
            return "Email is not valid";
        } else {
            return "";
        }
    },
    passwordValidation: (password: string, confirm_password: string) => {
        const passwordRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^A-Za-z0-9])/;

        if (password == '' || password == null) {
            return "Password field is required";
        } else if (confirm_password == '' || confirm_password == null) {
            return "Please confirm your password";
        } else if (password?.trim()?.length > 20 || password?.trim()?.length < 8) {
            return "Password length should be between 8 and 20 characters";
        } else if (!passwordRegex.test(password.trim())) {
            return "Please choose a stronger password";
        } else if (password?.trim() != confirm_password?.trim()) {
            return "Password confirmation failed";
        } else {
            return "";
        }
    },
    urlRequiredValidation: function(value: any, length = 255, required=true){
        if (((value?.toString()?.trim()) == "" || value == null) && required) {
            return `URL field is required`;
        } else {
            try {
                new URL(value);
                return "";
            } catch (error) {
                return "URL is not valid";
            }
        }
    },
    urlValidation: function (value:any,length= 255) {
        if((value?.toString()?.trim()) == "" || value == null){
            return "";
        }else{
            try {
                new URL(value);
                return "";
            } catch (error) {
                return "URL is not valid";
            }
        }
    },
    timeValidation: function (value: any) {
        const timeRegex = /^([01]?\d|2[0-3]):([0-5]\d)$/;
        if(!timeRegex.test(value) && value != "" && value != null){
            return "Invalid time format";
        } else {
            return "";
        }
    },
    timeRequiredValidation: function (value: any) {
        const timeRegex = /^([01]?\d|2[0-3]):([0-5]\d)$/;
        if (value == "" || value == null) {
            return "This field is required";
        }else if(!timeRegex.test(value)){
            return "Invalid time format";
        } else {
            return "";
        }
    },
    dateRequiredValidation: function (value: any) {
        const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
        if (value == "" || value == null) {
            return "This field is required";
        }
        else if (!dateRegex.test(value)) {
          return "Invalid date format";
        } else {
            return "";
        }
    },
    dateTimeValidation: function (date: string, regex = /^\d{4}-\d{2}-\d{2}T([01]?\d|2[0-3]):([0-5]\d)$/, fieldtitle = 'Date-Time') {
        if (!regex.test(date) && date != null && date != '') {
            return `${fieldtitle} format is incorrect`
        } else {
            return ""
        }
    },
    dateTimeRequiredValidation: function (date: string, regex = /^\d{4}-\d{2}-\d{2}T([01]?\d|2[0-3]):([0-5]\d)$/, fieldtitle = 'Date-Time') {
        if (date == "" || date == null) {
            return "This field is required";
        }else if (!regex.test(date)) {
            return `${fieldtitle} format is incorrect`
        } else {
            return ""
        }
    },
};
export default FormValidation;
