import { SWITCH_TAB, SET_ERROR, UPDATE_FIELD, UPDATE_INITIAL_DATA } from "./Action";
import { GeneralDetailsTab,AdministratorsTab, FormState } from "./Interfaces";
import { handleUpdateField,handleSetError, handleInitialStateUpdate} from "./Utils";

const FormReducer = (state: FormState, action: any): FormState => {
    const tabState = state[state.currentTab] as GeneralDetailsTab | AdministratorsTab;

    switch (action.type) {
        case UPDATE_FIELD:
            return handleUpdateField(tabState, state, action);
        
        case UPDATE_INITIAL_DATA:
            return handleInitialStateUpdate(state, action);

        case SET_ERROR:
            return handleSetError(state, action);

        case SWITCH_TAB:
            return {
                ...state,
                currentTab: action.value,
                tabs: state.tabs.map((tab) =>
                    tab.id === action?.value ? { ...tab, error: false } : tab
                ),
            };

        default:
            return state;
    }
};

export default FormReducer;
