import React, { useEffect, useState } from "react";
import { APICALL } from "../../../services/ApiServices";
import { fileData, Forms } from "../../../routes/ApiEndpoints";
import Layout from "../../organisms/forms/Layout";
import { formInitialState } from '../../organisms/forms/State';
import CustomNotify from "../../atoms/CustomNotify";
import ImageUpload from "../../../utils/icons/ImageUpload";
import { useNavigate } from "react-router-dom";

const ViewFormTemplate: React.FC = () => {
  const [state, setState] = useState([]);
  const [formState, setFormState] = useState(formInitialState);
  const searchParams = new URLSearchParams(window.location.search);
  const formId = searchParams.get("id");
  const token = searchParams.get("token");
  const navigate = useNavigate();
  const fileDetails: Record<string, any[]> = {};

  useEffect(() => {
    fetchData();
  }, []);

  const createFormValidateConst = (field:any)=>{
    const { name,type,required} = field;
    switch(type){
      case 'text':
        return  {
          name: name,
          type: 'text',
          validation: required ? 'required_text' :'not_required_text',
        }

      case 'location_time':
        return  {
            name: name,
            type: 'text',
            validation: required && 'required_text',
        }

      case 'email':
        return {
          name: name,
          type: 'email',
          validation: required ? 'required_email' : 'email',
        }

      case 'dropdown':
        return {
          name: name,
          type: 'dropdown',
          validation: required && 'select'
        }

      case 'textarea':
        return  {
          name: name,
          type: 'textarea',
          validation: required && 'textarea'
        }

      case 'text_input_single_line':
        return  {
          name: name,
          type: 'textarea',
          validation: required && 'textarea'
        }

      case 'radio':
        return {
          name: name,
          type: 'radio',
          validation: required && 'radio'
        }

      case 'single_checkbox':
        return {
          name: name,
          type: 'checkbox',
          validation: required && 'single_checkbox'
        }

      case 'url':
        return {
          name: name,
          type: 'url',
          validation: required ? 'required_url' : 'not_required_url',
        }

      case 'time':
        return {
          name: name,
          type: 'time',
          validation: required ? 'required_time' : 'time',
        }

      case 'date':
        return {
          name: name,
          type: 'date',
          validation: required ? 'required_date' : 'date',
        }

      case 'datetime':
        return {
          name: name,
          type: 'datetime-local',
          validation: required ? 'required_date_time' : 'date_time',
        }

      case 'file':
        return {
          name: name,
          type: 'file',
          validation: required && 'file',
        }

      default:
        return {};
    }
  }

  const initialStateForSubFields = (fields:any,initialState:any) =>{
    fields.forEach((field:any) => {
      const { name,type} = field;
      if(type==='single_checkbox'){
        initialState.form[name] = field.value ? field.value : false;
        initialState.errors[name] =field.value ? field.value : "";
      }else if(type === 'file'){
        field.icon = <ImageUpload/>;
        field.preview = false;
        initialState.form[name] =field.value ? field.value : {file:[],file_path:[],file_name:[]};
        initialState.errors[name] = "";
      }else if(type === 'sublevel'){
        initialStateForSubFields(field.fields,initialState);
      }else if(type != "h1" && type !='h2' && type !='h3' && type!='hr'){
        initialState.form[name] = field.value ? field.value : "";
        initialState.errors[name] = "";
      }
      initialState.validateForm.push(createFormValidateConst(field));
    });
    return initialState
  }

  const generateInitialState = (fields: any[]) => {
    const initialState: any = {
      form: {},
      validateForm: [],
      saving: false,
      errors: {},
    };
    return initialStateForSubFields(fields,initialState);
  };
  
  const fetchData = async () => {
    try {
      if (formId) {
        const response = await APICALL.service(Forms + "/view/" + formId, "GET",'',false,token);
        if (response?.data) {
          setState(response?.data);
          let formChangeData = generateInitialState(response?.data)
          setFormState(formChangeData)
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const removeError = (fieldName: string,key?:string) => {
    if (key) {
      const updatedForm: any = { ...formState.form };
      // Find the index of the item to remove
      const index = updatedForm?.[key]?.file_path.indexOf(fieldName);

      if (index !== -1) {
        // Create a copy of the state and remove the item
        updatedForm[key].file.splice(index, 1);
        updatedForm[key].file_path.splice(index, 1);
        updatedForm[key].file_name.splice(index, 1);

        // Update the state with the modified form data
        setFormState((prevState: any) => ({
          ...prevState,
          form: updatedForm,
        }));
      }
    } else if (formState.errors.hasOwnProperty(fieldName)) {
        setFormState((prevState: any) => ({
          ...prevState,
          errors: {
            ...prevState.errors,
            [fieldName]: "",
          },
        }));
    }
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement > | any,
    field: any
  ) => {
    if(e === null){
      setFormState((prevState:any) => ({
        ...prevState,
        form: {
          ...prevState.form,
          [field]: null,
        },
      }));
    }else if(e && e.size!=null){
      setFormState((prevState:any) => ({
        ...prevState,
        form: {
          ...prevState.form,
          [field?.name]:
            {
              file: [...prevState.form[field?.name].file, e],
              file_path: [...prevState.form[field.name].file_path, URL.createObjectURL(e)],
              file_name: [...prevState.form[field.name].file_name, e?.name],
            }
          ,
        },
      }));
    }else if (e && e.value !== undefined) {
      const selectedValue = e.value;
      setFormState((prevState:any) => ({
        ...prevState,
        form: {
          ...prevState.form,
          [field]: selectedValue,
        },
      }));
    } else {
        const { value, type, checked } = e.target;
        setFormState((prevState:any) => ({
          ...prevState,
          form: {
            ...prevState.form,
            [field]: type === 'checkbox' ? checked : type === 'number' ? ((value && parseInt(value) > 1) ? parseInt(value, 10) : 1) : value
          },
        }));
      }
    
  };
  
  const handleDateFormat = (dateValue: any, field: string) => {
    // onchange of the date
    if (dateValue !== null) {
      // formatting and setting the state with the formatted date
      const date = new Date(dateValue);
      const timezoneOffset = date.getTimezoneOffset() * 60 * 1000; // To set time zone offset
      const formattedDate = new Date(date.getTime() - timezoneOffset)
        .toISOString()
        .slice(0, 10);
        setFormState((prevState: any) => ({
        ...prevState,
        form: {
          ...prevState.form,
          [field]: formattedDate,
        },
      }));
    } else {
      // setting the state with the null when it is cleared
      setFormState((prevState: any) => ({
        ...prevState,
        form: {
          ...prevState.form,
          [field]: null,
        },
      }));
    }
  };
  const handleSubmit = async(errors: any, valid: boolean) => {
    setFormState((prevState: any) => ({
      ...prevState,
      saving: true
    }));
    if(valid){
    try {
      const object:any = {};
      const loadingIcon = document.getElementById("loading-div-id");
      if (loadingIcon) loadingIcon.style.display = "block";
      // Upload files if present
      for (const [key, value] of Object.entries(formState?.form || {})) {
        if (key && value !== null && typeof value === "object" && "file" in value && value?.file) {
          const fileObj = value.file;
          const filesToUpload = Array.isArray(fileObj) ? fileObj : [fileObj];

          const uploadPromises = filesToUpload.map(async (fileValue) => {
              const formData = new FormData();
              formData.append("file", fileValue);
              const path = `forms/${key}`;
              formData.append("path", path);
              try {
                  const response = await APICALL.service(fileData, "POST", formData,false,token);
                  if (response?.status === 200) {
                      const { fileId } = response?.data;
                      object[key] = object[key] || [];
                      object[key].push(fileId);
                  } else {
                      throw new Error("File upload failed");
                  }
              } catch (error) {
                  console.error(`Error uploading file for field "${key}": ${error}`);
                  throw new Error("File upload failed");
              }
          });
          await Promise.all(uploadPromises);
        }
      }
  if (loadingIcon) loadingIcon.style.display = "none";
  if (formId) {
      const newFormData = Object.entries(formState.form).map(([key, value]) => {
          const isEmptyFileObject = (
            value &&
            typeof value === "object" &&
            "file" in value &&
            "file_path" in value &&
            "file_name" in value &&
            Array.isArray((value as any).file) &&
            Array.isArray((value as any).file_path) &&
            Array.isArray((value as any).file_name) &&
            (value as any).file.length === 0 &&
            (value as any).file_path.length === 0 &&
            (value as any).file_name.length === 0
        );
          const fileObj = Object.entries(object).map(([fileKey, fileValue]) => {
              if (key === fileKey) {
                  return { key, value: fileValue };
              }
          }).find(Boolean);
          return fileObj || { key, value: isEmptyFileObject ? null : value };
      });
      const flattenedFormData = newFormData.flat().filter(item => item !== null);
      const response = await APICALL.service(Forms + "/create/" + formId, "POST", flattenedFormData,false,token);
      if (response) {
          CustomNotify({
              type: response.status === 200 ? "success" : "error",
              message: response.message,
          });
          setFormState((prevState) => ({
              ...prevState,
              saving: false
          }));
          if (response.status === 200) {
            navigate("/forms/manage");
          }

      }
  }
  } catch (error) {
    console.error("Error during form submission:", error);
  }
    }else{
      setFormState((prevState: any) => ({
        ...prevState,
        saving: false,
        errors: errors,
      }));
    }
  };

  return (
    <div className="d-flex flex-column align-items-center p-3">
      <Layout
        removeError={removeError}
        handleChange={handleChange}
        handleDateFormat={handleDateFormat}
        values = {state}
        validateForm = {formState.validateForm}
        data = {formState.form}
        errors = {formState.errors}
        handleSubmit={handleSubmit}
        saving = {formState.saving}
      />
    </div>
  );
};

export default ViewFormTemplate;
