import { t } from "../../../services/translation/TranslationUtils";
import Camera from "../../../utils/icons/Camera";

export const formValues = (options: any) => {
    let row = [
        {
            0: {
                name: 'first_name',
                type: 'text',
                required: true,
                validation: 'required_text',
                value: '',
            },
            1: {
                name: 'last_name',
                type: 'text',
                required: true,
                value: '',
                validation: 'required_text'
            }
        },
        {
            0: {
                name: 'gender',
                type: 'select',
                required: true,
                value: null,
                validation: 'select',
                options: options?.Gender,
            },
            1: {
                name: 'date_of_birth',
                type: 'date',
                value: null
            },
            2: {
                name: 'phone',
                type: 'phone-number',
                required: true,
                validation: 'phone',
                value: ''
            }
        },
        {
            0: {
                name: 'reference',
                type: 'text',
                value: '',
                validation: 'not_required_text'
            },
            1: {
                name: 'employed_from',
                type: 'date',
                value: null
            },
            2: {
                name: 'employed_till',
                type: 'date',
                value: null
            }
        },
        {
            0: {
                name: 'role',
                type: 'select',
                required: true,
                value: null,
                validation: 'select',
                options: options?.Role
            },
            1: {
                name: 'title',
                type: 'text',
                value: '',
                validation: 'not_required_text'
            },
            2: {
                name: 'username',
                type: 'text',
                required: true,
                validation: 'required_text',
                value: ''
            },
        },
        {
            0: {
                name: 'language',
                type: 'select',
                value: null,
                options: options?.Language
            },
            1: {
                name: 'business_unit',
                type: 'select',
                value: null,
                options: options?.Group
            },
            2: {
                name: 'office',
                type: 'text',
                value: ''
            },
        },
        {
            0: {
                name: 'sub_groups',
                type: 'text',
                value: '',
            },
            1: {
                name: 'contract_type',
                type: 'select',
                value: null,
                options: options?.ContractType
            },
            2: {
                name: 'email',
                type: 'text',
                validation: 'email'
            }
            // 2: {
            //     name: 'active',
            //     type: 'radio'
            // }
        }
    ]
    return row;
}

export const filterOptions = [
    {
        model: "Role",
        filters: {
            status: [true]
        }
    },
    {
        model: "ContractType",
        filters: {
            status: [true]
        }
    },
    {
        model: "Group",
        filters: {
            status: [true],
            names: ['business_unit']
        }
    }
];

export const options = [
    {
        model: "Gender",
        filters: {
            status: [true]
        }
    },
    {
        model: "Company",
        fields: {
            id: "value",
            name: "label"
        },
        filters: {
            status: [true]
        }
    },
    {
        model: "Role",
        filters: {
            status: [true]
        }
    },
    {
        model: "Language",
        filters: {
            status: [true]
        }
    },
    {
        model: "ContractType",
        filters: {
            status: [true]
        }
    },
    {
        model: "Group",
        filters: {
            status: [true],
            names: ['business_unit']
        }
    }
];

export const initialstate = {
    form: {
        first_name: '',
        last_name: '',
        gender: null,
        date_of_birth: '',
        office: '',
        phone: '',
        refernce: '',
        employed_from: '',
        employed_till: '',
        company: null,
        role: null,
        title: '',
        username: '',
        language: null,
        business_unit: null,
        sub_groups: null,
        contract_type: null,
        email: '',
        active: true,
        profile_picture: {
            file: null as any,
            file_path: '',
            file_name: ''
        }
    },
    options: {} as any,
    saving: false,
    errors: {
        first_name: '',
        last_name: '',
        phone: '',
        company: '',
        role: '',
        username: '',
        email: '',
        title: ''
    }
}

export const tableHeaders = [
    { name: 'Name', width: 23 },
    { name: 'Role', width: 21 },
    { name: 'Phone number', width: 19 },
    { name: 'Activated at', width: 15 },
    { name: 'Activity', width: 10 },
];

export const tableValues = [
    'name',
    'role',
    'phone',
    'activated_at',
    'activity'
];

export const manageState = {
    filters: {
        first_name: '',
        last_name: '',
        groups: [],
        roles: [],
        contracttypes: [],
        total_pages: 1,
        current_page: 1,
        limit: 10,
        status: null
    },
    options: {} as any,
    data: [] as any,
    popup: false
};

export const filterValues = (options: any) => {
    let row = [
        {
            name: 'first_name',
            type: 'text',
            label: true,
            placeholder: 'First name',
            value: ''
        },
        {
            name: 'last_name',
            type: 'text',
            label: true,
            placeholder: 'Last name',
            value: ''
        },
        {
            name: 'groups',
            type: 'multi-select-without-label',
            placeholder: 'Select business unit',
            value: [],
            options: options?.Group,
        },
        {
            name: 'roles',
            type: 'multi-select-without-label',
            placeholder: 'Select roles',
            value: [],
            options: options?.Role,
        },
        {
            name: 'contracttypes',
            type: 'multi-select-without-label',
            placeholder: 'Select contract types',
            value: [],
            options: options?.ContractType,
        }
    ]
    return row;
}

export const fileImportField = {
    name: 'file',
    icon: t('Import'),
    required: false,
    title: '',
    accept: '.xlsx, .xls',
    hovertext: 'Import employee',
    class: {
        mainclass: "create_button cursor-pointer"
    }
}

export const profileValues = (options: any) => {
    let row = [
        {
            0: {
                name: 'first_name',
                type: 'text',
                required: true,
                value: '',
                validation: 'required_text'
            },
            1: {
                name: 'last_name',
                type: 'text',
                required: true,
                value: '',
                validation: 'required_text'
            }
        },
        {
            0: {
                name: 'role',
                type: 'text',
                value: '',
                disable: true
            },
            1: {
                name: 'title',
                type: 'text',
                value: '',
                disable: true
            }
        },
        {
            0: {
                name: 'phone',
                type: 'text',
                value: '',
                disable: true
            },
            1: {
                name: 'language',
                type: 'select',
                value: null,
                options: options?.Language
            }
        }
    ]
    return row;
}

export const initialProfileState = {
    form: {
        first_name: '',
        last_name: '',
        phone: '',
        role: '',
        title: '',
        language: null,
        profile_picture: 
        {
            file: null as any,
            file_path: '',
            file_name: '',
            fileId: null
        }
    },
    options: {} as any,
    errors: {
        first_name: '',
        last_name: ''
    }
}

export const profileOptions = [
    {
        model: "Language",
        filters: {
            status: [true]
        }
    }
];

export const fileProfileField = {
    name: 'profile_picture',
    icon: <Camera />,
    required: false,
    title: '',
    accept: '.png, .jpg, .jpeg,',
    hovertext: t('Profile picture'),
    maxSize: 2 * 1024 * 1024,
    class: {
        mainclass: ''
    }
}

export const passwordState = {
    form: {
        current_password: '',
        new_password: '',
        confirm_password: ''
    },
    type: {
        current_password: 'password',
        new_password: 'password',
        confirm_password: 'password'
    },
    errors: {
        current_password: '',
        new_password: '',
    }
}
