const CommonServices = {
  capitalizeLabel: function (str: string): string {
    let convertedstr = "";
    convertedstr = str.replace(/\d+$/, "");
    convertedstr = convertedstr.charAt(0).toUpperCase() + convertedstr.slice(1);
    convertedstr = convertedstr.replace(/_/g, " ");
    return convertedstr;
  },
  convertIntoModelName: function (str: string): string {
    const words = str.split("_");
    const capitalizedWords = words.map(
      (word) => word.charAt(0).toUpperCase() + word.slice(1)
    );
    return capitalizedWords.join("");
  },
  stringFormat: function (str: string): string {
    let convertedstr = str.replaceAll("_", " ");
    convertedstr = convertedstr.charAt(0).toUpperCase() + convertedstr.slice(1);
    return convertedstr;
  },
  formatDateIntoLocalDateTime: function (
    timestamp: string,
    includeTime: boolean = true
  ) {
    if (timestamp) {
      const date = new Date(timestamp); // Create a Date object from the timestamp

      // Array of month names
      const months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];

      // Get the day, month, year, hours, and minutes in local time
      const day = date.getDate();
      const month = months[date.getMonth()];
      const year = date.getFullYear();
      const hours = date.getHours();
      const minutes = date.getMinutes();

      // Format day, hours, and minutes to have leading zeros if needed
      const formattedDay = day.toString().padStart(2, "0");
      const formattedHours = hours.toString().padStart(2, "0");
      const formattedMinutes = minutes.toString().padStart(2, "0");

      // Construct the final formatted date string
      const formattedDateTime = includeTime
        ? `${formattedDay} ${month} ${year} ${formattedHours}:${formattedMinutes}`
        : `${formattedDay} ${month} ${year}`;

      return formattedDateTime;
    }
  },
  convertToISODate: (date: string | Date | null): string | null => {
    if (!date) return null;
    let parsedDate;
    if (typeof date === "string") {
      // Try to parse the date string
      parsedDate = new Date(date);
      if (isNaN(parsedDate?.getTime())) {
        return null;
      }
    } else if (date instanceof Date) {
      parsedDate = date;
    } else {
      return null;
    }
    // Adjust for timezone offset
    const timezoneOffset = parsedDate?.getTimezoneOffset() * 60000;
    const adjustedDate = new Date(parsedDate?.getTime() - timezoneOffset);
    return adjustedDate?.toISOString();
  },
};
export default CommonServices;
