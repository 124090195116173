import React from "react";
import { ViewPostsProps } from "../../../../pages/news/context/Interfaces";
import { ACTION_POSTS } from "../../../../routes/ApiEndpoints";
import { APICALL } from "../../../../services/ApiServices";
import CommentsIcon from "../../../../utils/icons/CommentsIcon";
import Like from "../../../../utils/icons/Like";
import Comments from "./Comments";
import '../CreateNewsOrganism.css';
import ShowMedia from "./ShowMedia";
import ShowPdf from "./ShowPdf";
import ShowSurvey from "./ShowSurvey";
import UserNameAndProfile from "./UserNameAndProfile";

const ViewPosts: React.FC<ViewPostsProps> = ({
    data,
    fetchPost
}) => {
    const handleLike = async (post: any) => {
        let postData = {
            type: 'like',
            entity_type: 'post',
            entity_id: post?.id,
            status: !post?.has_liked
        };
        const response = await APICALL.service(ACTION_POSTS, 'POST', postData);

        if (response.status === 200) {
            fetchPost(post?.id);
        }
    };

    return (
        <div className="col-12 col-xl-8 mx-auto mt-3">
            {data.posts?.length ? data.posts?.map((post: any) => (
                <div key={post?.id} className="border mb-4 p-5 ">
                    <div className="mb-2">
                        <UserNameAndProfile
                            user={post?.post_user}
                            created_at={post?.created_at}
                        />
                    </div>
                    <div className="">
                        <div className="mb-2 text-break" dangerouslySetInnerHTML={{ __html: post?.body }}></div>
                        {(post?.video?.length > 0 || post?.image?.length > 0) &&
                            <ShowMedia
                                videos={post?.video}
                                images={post?.image}
                            />
                        }
                        {post?.pdf?.length > 0 &&
                            <ShowPdf
                                pdf={post?.pdf}
                            />
                        }
                        {(post?.survey?.length > 0 && post?.survey?.[0]?.has_permission) && <ShowSurvey
                            survey={post?.survey[0]}
                            postId={post?.id}
                            fetchPost={fetchPost}
                        />}
                        <div className="mt-3">
                            <span
                                onClick={() => handleLike(post)}
                            >
                                <Like liked={post?.has_liked} />
                            </span> {post?.likes_count} {' '}
                            <CommentsIcon /> {post?.comments_count}
                        </div>
                        <Comments
                            postId={post.id}
                            comments={post?.comments}
                            fetchPost={fetchPost}
                        />
                    </div>

                </div>
            )) :
                <div>
                    {"no posts"}
                </div>
            }
        </div>
    );
}

export default ViewPosts;