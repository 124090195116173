import React from "react";
import ViewFormTemplate from "../../components/templates/forms/ViewFormTemplate";

const ViewForm: React.FC = () => {
  return (
    <ViewFormTemplate />
  );
};

export default ViewForm;
